<template>
  <div>
    <!-- Top Control Buttons -->
    <b-row class="mb-2">
      <b-col cols="8">
        <h4 class="mb-1">
          <feather-icon
            icon="DownloadIcon"
            size="22"
            class="mr-50"
          />
          Camera Media Downloads -- {{ cameraData.sitename }}, {{ cameraData.location_name }}<br>
        </h4>
        <small>Camera: {{ cameraData.unit_number }}</small>
        <b-badge
          v-if="!cameraData.is_active"
          pill
          variant="light-danger"
          class="text-capitalize ml-1"
        >
          Disabled
        </b-badge>
        <br>
      </b-col>
    </b-row>
    <b-row class="mb-2">
      <b-col>
        <b-tabs>
          <b-tab active>
            <template #title>
              <feather-icon
                icon="ImageIcon"
                size="18"
                class="mr-50"
              />
              <span class="font-weight-bold">Images</span>
            </template>
            <div>
              <camera-edit-tab-image-downloads
                ref="CameraEditTabImageDownloads"
                :camera-data="cameraData"
              />
            </div>
          </b-tab>
          <!-- <b-tab>
            <template #title>
              <feather-icon
                icon="VideoIcon"
                size="18"
                class="mr-50"
              />
              <span class="font-weight-bold">Videos</span>
            </template>
            <div>
              <camera-edit-tab-video-downloads
                :camera-data="cameraData"
              />
            </div>
          </b-tab> -->
        </b-tabs>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BTabs, BTab, BRow, BCol, BBadge,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import CameraEditTabImageDownloads from './CameraEditTabImageDownloads.vue'
// import CameraEditTabVideoDownloads from './CameraEditTabVideoDownloads.vue'

export default {
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    BBadge,

    CameraEditTabImageDownloads,
    // CameraEditTabVideoDownloads,
  },
  props: {
    cameraData: {
      type: Object,
      default: null,
    },
  },
  setup() {
    const formatDateTimeAMPM = date => {
      const theDate = new Date(date.replace(/-/g, '/'))
      const year = theDate.getFullYear()
      let month = theDate.getMonth() + 1
      if (month < 10) month = `0${month}`
      let day = theDate.getDate()
      if (day < 10) day = `0${day}`
      let hours = theDate.getHours()
      let minutes = theDate.getMinutes()
      const ampm = hours >= 12 ? 'PM' : 'AM'
      hours %= 12
      hours = hours || 12
      minutes = minutes.toString().padStart(2, '0')
      const strTime = `${year}-${month}-${day} ${hours}:${minutes} ${ampm}`
      return strTime
    }

    return {
      formatDateTimeAMPM,
    }
  },
  methods: {
    onZIPCreated() {
      console.log('onZIPCreated')
      // Refresh the list
      this.$refs.CameraEditTabImageDownloads.handleRefreshList()
      // Show Toast
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'ZIP File created. Please see the list to download.',
          icon: 'DownloadIcon',
          variant: 'warning',
        },
      })
    },
  },
}
</script>
