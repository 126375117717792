<template>
  <div class="app-calendar overflow-hidden">
    <!-- Media -->
    <b-media class="mb-2">
      <template #aside>
        <!-- TODO: Show the latest Image here -->
      </template>
      <h4 class="mb-1">
        <feather-icon
          icon="CameraIcon"
          size="22"
          class="mr-50"
        />
        Media Calendar -- {{ cameraData.sitename }}, {{ cameraData.location_name }}<br>
      </h4>
      <small>Camera: {{ cameraData.unit_number }}</small>
      <b-badge
        v-if="!cameraData.is_active"
        pill
        variant="light-danger"
        class="text-capitalize ml-1"
      >
        Disabled
      </b-badge>
      <br>
    </b-media>

    <b-row>
      <b-col>
        <div class="row no-gutters">
          <!-- Sidebar -->
          <!-- <div
            class="col app-calendar-sidebar flex-grow-0 overflow-hidden d-flex flex-column"
            :class="{'show': isCalendarOverlaySidebarActive}"
          >
            <calendar-sidebar :is-event-handler-sidebar-active.sync="isEventHandlerSidebarActive" />
          </div> -->

          <!-- Calendar -->
          <div class="col position-relative">
            <div class="card shadow-none border-0 mb-0 rounded-0">
              <div class="card-body pb-0">
                <full-calendar
                  ref="refCalendar"
                  :options="calendarOptions"
                  class="full-calendar"
                />
              </div>
            </div>
          </div>

          <!-- Sidebar Overlay -->
          <div
            class="body-content-overlay"
            :class="{'show': isCalendarOverlaySidebarActive}"
            @click="isCalendarOverlaySidebarActive = false"
          />
          <calendar-event-handler
            v-model="isEventHandlerSidebarActive"
            :camera-data="cameraData"
            :event="event"
            :clear-event-data="clearEventData"
            @itemclicked="handleFilmstripClick"
          />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BMedia, BRow, BCol, BBadge,
} from 'bootstrap-vue'
import FullCalendar from '@fullcalendar/vue'
// import CalendarSidebar from '../../calendar/calendar-sidebar/CalendarSidebar.vue'
import CalendarEventHandler from './calendar-event-handler/CalendarEventHandler.vue'
import useCalendar from './useCalendar'

export default {
  components: {
    BMedia,
    BRow,
    BCol,
    BBadge,
    FullCalendar,
    // CalendarSidebar,
    CalendarEventHandler,
  },
  props: {
    cameraData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      status: '',
      options: {},
      signalOptions: {},
      theImage: '',
    }
  },
  setup(props) {
    const {
      refCalendar,
      isCalendarOverlaySidebarActive,
      event,
      selectedCalendars,
      clearEventData,
      fetchEvents,
      refetchEvents,
      calendarOptions,

      // ----- UI ----- //
      isEventHandlerSidebarActive,
    } = useCalendar(props.cameraData)

    // fetchEvents()

    return {
      refCalendar,
      isCalendarOverlaySidebarActive,
      event,
      selectedCalendars,
      clearEventData,
      refetchEvents,
      fetchEvents,
      calendarOptions,

      // ----- UI ----- //
      isEventHandlerSidebarActive,
    }
  },
  created() {
    // this.$emit('showContentTab')
  },
  methods: {
    handleFilmstripClick(item) {
      console.log('handleFilmstripClick')
      console.log(item)
      this.isEventHandlerSidebarActive = false
      this.$emit('showContentForDateTime', item)
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";
</style>
