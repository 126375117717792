<template>
  <div>
    <div>
      <b-row class="mb-1">
        <b-col cols="6">
          <h4 class="mb-1">
            <feather-icon
              icon="CameraIcon"
              size="22"
              class="mr-50"
            />
            Linking / Embedding -- {{ cameraData.sitename }}, {{ cameraData.location_name }}
          </h4>
          <small>Camera: {{ cameraData.unit_number }}</small><br>
        </b-col>
      </b-row>
      <b-row class="mt-2 mb-1 border-bottom-primary">
        <b-col>
          <h4>Image Linking and Embedding</h4>
        </b-col>
      </b-row>
      <b-row
        v-if="$can('update', 'cameras') || $can('update', 'camerasmanagement')"
        class="mt-2"
      >
        <b-col>
          <b-form-checkbox
            id="is_demo"
            v-model="cameraData.is_demo"
            name="is_demo"
            input-id="size_selection"
            return-object
            @input="updateCameraDemoSetting"
          >
            Enable Linking / Embedding
          </b-form-checkbox>
        </b-col>
      </b-row>
      <b-row
        v-if="($can('update', 'cameras') || $can('update', 'camerasmanagement')) && cameraData.is_demo && cameraUUID !== null"
        class="mt-2"
      >
        <b-col cols="12">
          <b-form-group
            label="Select Size to Display Image"
            label-for="size_selection"
          >
            <v-select
              id="size_selection"
              v-model="selectedResolution"
              :options="sizeOptions"
              :reduce="val => val.value"
              :clearable="false"
              input-id="size_selection"
              return-object
              @input="onSizeSelect"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row
        v-if="($can('update', 'cameras') || $can('update', 'camerasmanagement')) && cameraData.is_demo && cameraUUID !== null && selectedResolution !== null"
        class="mt-2"
      >
        <b-col>
          <b-form-group
            label="Embed HTML Content"
            label-for="embed_content"
          >
            <b-form-textarea
              id="embed_content"
              ref="embedTA"
              :value="embedContent"
              rows="3"
              max-rows="6"
              @focus="$event.target.select()"
            />
          </b-form-group>
          <b-button
            variant="primary"
            class="pl-4 pr-4"
            @click="copyToClipboard"
          >
            Copy
          </b-button>
        </b-col>
      </b-row>
      <b-row
        v-if="($can('update', 'cameras') || $can('update', 'camerasmanagement')) && cameraData.is_demo && cameraUUID !== null && selectedResolution !== null"
        class="mt-2"
      >
        <b-col>
          <!-- Next Button -->
          <b-button
            id="previewBtn"
            variant="primary"
            class="pl-4 pr-4"
            @click="previewiFrame"
          >
            Preview
          </b-button>
        </b-col>
      </b-row>
    </div>
    <!-- Video Modal -->
    <b-modal
      id="vmodal"
      ref="vmodal"
      ok-only
      ok-title="Close"
      centered
      size="xl"
      :title="previewTitle"
      @close="showIframeModal = false"
    >
      <!-- eslint-disable-next-line vue/no-v-html -->
      <p v-html="embedContent" />
    </b-modal>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormCheckbox, BFormTextarea, BButton,
} from 'bootstrap-vue'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import ability from '../../../../libs/acl/ability'

export default {
  components: {
    BRow,
    BCol,
    BFormCheckbox,
    BFormTextarea,
    BFormGroup,
    vSelect,
    BButton,
  },
  props: {
    cameraData: {
      type: Object,
      default: null,
    },
  },
  setup() {
    const sizeOptions = store.state.appSettings.camera_embed_resolution_options
    const apiEndpoint = process.env.VUE_APP_AMPLIFY_API_ENDPOINTS_APIGATEWAY_ENDPOINT
    console.log(apiEndpoint)

    return {
      sizeOptions,
      apiEndpoint,
    }
  },
  data() {
    return {
      cameraUUID: null,
      selectedResolution: null,
      embedContent: '',
      previewTitle: '',
      showIframeModal: false,
    }
  },
  created() {
    this.getCameraUUID()
  },
  methods: {
    onSizeSelect(selectedVal) {
      console.log('onSizeSelect')
      console.log(selectedVal)
      const res = selectedVal.split('x')
      console.log(res)
      this.embedContent = `<iframe src="${this.apiEndpoint}/htmlembed/${this.cameraUUID}?width=${res[0]}" width="${res[0]}" height="${res[1]}" style="border: none;" frameborder="0" scolling="no"></iframe>`
      this.previewTitle = `${res[0]}px x ${res[1]}px`
    },
    getCameraUUID() {
      // Call API to tell camera to take picture
      if (ability.can('update', 'cameras') || ability.can('update', 'camerasmanagement')) {
        this.cameraUUID = null
        const qvars = {
          camera_id: this.cameraData.camera_id,
        }
        store
          .dispatch('app-cameraconsole/fetchCameraUUID', qvars)
          .then(response1 => {
            console.log('app-cameraconsole/fetchCameraUUID')
            console.log(response1)
            this.cameraUUID = response1.uuid
            console.log(this.cameraUUID)
          })
          .catch(error => {
            console.log(error)
            this.showLoadingOverlay = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Error getting camera data. ${error}`,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
    },
    updateCameraDemoSetting(selectedVal) {
      // Call API to tell camera to take picture
      if (ability.can('update', 'cameras') || ability.can('update', 'camerasmanagement')) {
        this.cameraUUID = null
        const qvars = {
          camera_id: this.cameraData.camera_id,
          is_demo: selectedVal,
          orig_cameraData: this.cameraData,
        }
        store
          .dispatch('app-camera/updateCamera', qvars)
          .then(response1 => {
            console.log('app-cameraconsole/updateCamera')
            console.log(response1)
            if (this.cameraData.is_demo) {
              this.getCameraUUID()
            }
          })
          .catch(error => {
            console.log(error)
            this.showLoadingOverlay = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Error getting camera settings. ${error}`,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
    },
    previewiFrame() {
      // Open player modal
      this.showIframeModal = true
      // eslint-disable-next-line no-undef
      console.log(this.$refs.vmodal)
      this.$refs.vmodal.show()
    },
    copyToClipboard() {
      console.log('copyToClipboard')
      // clipboard.writeText(this.embedContent)
      this.$refs.embedTA.focus()
      document.execCommand('copy')
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '~@core/scss/base/pages/app-ecommerce.scss';
</style>
