<template>
  <div>
    <b-overlay
      :show="showLoadingOverlay"
      rounded="sm"
      variant="transparent"
      blur="5px"
    >
      <filmstrip-sidebar
        :is-filmstrip-sidebar-active.sync="isFilmstripSidebarActive"
        :camera-id-prop.sync="cameraData.unit_number"
        :camera-data.sync="cameraData"
        :camera-media.sync="cameraMedia"
        :current-image-date-time.sync="currentImageDateTime"
        @itemclicked="handleFilmstripClick"
        @getcameramedia="handleFilmstripGetCameraMedia"
        @setcurrentimage="handleFilmstripSetCurrentImage"
        @setcurrentdatetime="handleFileStripSetCurrentDateTime"
      />
      <!-- Main Image -->
      <div>
        <!-- Top Control Buttons -->
        <b-row
          class="mb-0"
        >
          <b-col class="col-12 col-md-8">
            <h4 class="mb-1">
              <feather-icon
                icon="CameraIcon"
                size="22"
                class="mr-50"
              />
              Images & Videos -- {{ cameraData.sitename }}, {{ cameraData.location_name }}<br>
            </h4>
            <small>Camera: {{ cameraData.unit_number }}</small>
            <b-badge
              v-if="!cameraData.is_active"
              pill
              variant="light-danger"
              class="text-capitalize ml-1"
            >
              Disabled
            </b-badge>
            <b-badge
              v-if="!cameraData.is_online"
              pill
              variant="light-warning"
              class="text-capitalize ml-1"
            >
              Offline
            </b-badge>
            <br>
            <small>Time Zone: {{ cameraData.time_zone }}</small><br>
          </b-col>
          <b-col
            class="col-12 col-md-4 text-right"
          >
            <b-button
              v-if="($can('update', 'cameras') || $can('update', 'camerasmanagement')) && cameraData.is_active && cameraData.is_online"
              id="button-3"
              variant="primary"
              class="mr-1"
              type="button"
              @click="takePicture"
            >
              <feather-icon icon="CameraIcon" />
            </b-button>
            <b-tooltip
              target="button-3"
              title="Take Picture"
              placement="bottom"
            />
            <b-button
              v-if="$can('read', 'cameras') || $can('read', 'camerasmanagement')"
              id="button-2"
              variant="primary"
              class="mr-1"
              type="button"
              :disabled="disableDownload"
              @click="downloadImage"
            >
              <feather-icon icon="DownloadIcon" />
            </b-button>
            <b-tooltip
              target="button-2"
              title="Download"
              placement="bottom"
            />
            <b-button
              id="sidebarBtn"
              variant="primary"
              type="button"
              @click="isFilmstripSidebarActive = true"
            >
              <feather-icon icon="SidebarIcon" />
            </b-button>
            <b-tooltip
              target="sidebarBtn"
              title="Open Filmstrip"
              placement="bottom"
            />
          </b-col>
        </b-row>
        <!-- Play Video Button -->
        <b-row
          v-if="isVideoDownloaded"
          class="mt-1 mt-md-0 mb-0"
        >
          <b-col
            cols="12"
            class="text-right"
          >
            <b-button
              id="playVideoBtn"
              variant="primary"
              type="button"
              @click="playVideoFile(currentVideoItem)"
            >
              Play Video
            </b-button>
            <b-tooltip
              target="playVideoBtn"
              title="Play Motion Video"
              placement="bottom"
            />
          </b-col>
        </b-row>
        <!-- Download Video Button -->
        <b-row
          v-else-if="hadMotionDetected && cameraData.is_online"
          class="mt-0 mb-0"
        >
          <b-col
            cols="12"
            class="mt-1 mt-md-0 text-right"
          >
            <b-button
              id="acknowledgeBtn"
              variant="primary"
              type="button"
              @click="playVideoFile(currentVideoItem)"
            >
              Download Video
            </b-button>
          </b-col>
        </b-row>
        <!-- Date / Time Row -->
        <b-row
          class="mb-0 mt-1"
        >
          <b-col
            cols="12"
            class="text-center"
          >
            <h3
              v-if="hadMotionDetected && currentImageDateTime !== undefined"
            >
              <span class="text-danger">Motion Alert: </span>{{ formatDateTimeAMPM(currentImageDateTime.replace('T', ' ')) }}
            </h3>
            <h3 v-else>
              {{ formatDateTimeAMPM(currentImageDateTime.replace('T', ' ')) }}
            </h3>
          </b-col>
        </b-row>
        <!-- Media -->
        <b-media class="mb-1">
          <lazy-image-component
            v-if="hadMotionDetected && theImage !== ''"
            class="rounded border-danger"
            fluid
            :src="theImage"
            src-placeholder="@/assets/images/noImage.png"
          />
          <lazy-image-component
            v-else-if="cameraData.latest_image !== undefined && cameraData.latest_image.access_url !== '' && theImage !== ''"
            :alt="`${cameraData.sitename}-${cameraData.location_name}`"
            class="rounded"
            fluid
            :src="theImage"
            src-placeholder="@/assets/images/noImage.png"
          />
          <!-- <b-img
            v-if="hadMotionDetected && theImage !== ''"
            :alt="`${cameraData.sitename}-${cameraData.location_name}`"
            class="rounded border-danger"
            fluid
            :src="theImage"
          />
          <b-img
            v-else-if="cameraData.latest_image !== undefined && cameraData.latest_image.access_url !== '' && theImage !== ''"
            :alt="`${cameraData.sitename}-${cameraData.location_name}`"
            class="rounded"
            fluid
            :src="theImage"
          /> -->
          <b-img
            v-else
            :alt="`${cameraData.unit_number}-${cameraData.camera_id}`"
            class="rounded"
            fluid
            src="@/assets/images/noImageLg.png"
          />
        </b-media>
        <!-- Control Buttons -->
        <b-row class="mt-0 mb-1 ml-0 mr-0">
          <!-- Previous Button -->
          <b-col
            class="col-3 col-xl-3 ml-0 mr-0"
          >
            <b-button
              id="prevBtn"
              class="pl-2 pr-2 pl-xl-4 pr-xl-4"
              variant="primary"
              :disabled="disablePreviousButton"
              @click="showPreviousImage"
            >
              <feather-icon icon="ArrowLeftIcon" />
            </b-button>
          </b-col>
          <!-- Date / Time Picker -->
          <b-col
            class="d-none d-md-block col-md-3 text-right ml-0 mr-0"
          >
            <div class="input-group">
              <flat-pickr
                v-model="currentDateTime"
                class="form-control"
                :config="{ enableTime: true, dateFormat: 'Y-m-d H:i', altInput: true, altFormat: 'F j, Y H:i', maxDate: maxDateTime, allowInput: true }"
                @on-close="onCloseImageDateTime"
                @on-open="showcheckBtn = true"
              />
              <div class="input-group-btn">
                <b-button
                  v-if="showcheckBtn"
                  class="pl-1 pr-1"
                  variant="primary"
                >
                  <feather-icon icon="CheckIcon" />
                </b-button>
              </div>
            </div>
          </b-col>
          <!-- Calendar Button -->
          <b-col
            class="col-2 col-md-1"
          >
            <b-button
              v-if="$can('read', 'cameras') || $can('read', 'camerasmanagement')"
              id="button-cal"
              variant="primary"
              class="mr-1"
              type="button"
              @click="openCalendar"
            >
              <feather-icon icon="CalendarIcon" />
            </b-button>
            <b-tooltip
              target="button-cal"
              title="Open Media Calendar"
              placement="bottom"
            />
          </b-col>
          <!-- Latest Image Button -->
          <b-col
            class="col-4 col-md-2"
          >
            <b-button
              id="latestBtn"
              variant="primary"
              @click="showLatestImage"
            >
              Latest
            </b-button>
            <b-tooltip
              target="latestBtn"
              title="Show Latest Image"
              placement="bottom"
            />
          </b-col>
          <!-- Next Button -->
          <b-col
            class="col-2 col-xl-3 text-right"
          >
            <b-button
              id="nextBtn"
              variant="primary"
              class="pl-2 pr-2 pl-xl-4 pr-xl-4"
              :disabled="disableNextButton"
              @click="showNextImage"
            >
              <feather-icon icon="ArrowRightIcon" />
            </b-button>
          </b-col>
        </b-row>
        <!-- Videos List Title -->
        <b-row class="mt-3 mb-1 border-bottom-primary">
          <b-col>
            <h4>Videos -- {{ displayCurrentImageDateTime }}</h4>
          </b-col>
        </b-row>
      </div>
      <!-- Videos List -->
      <div
        class="ecommerce-application"
        style="height: inherit"
      >
        <!-- Videos List Filters -->
        <b-row
          v-if="videoItems !== null && videoItems.length"
          class="mt-2 mb-1"
        >
          <!-- Per Page / Search -->
          <b-col
            md="4"
            class="col-push-12 col-md-4 d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="filters.perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>
          <b-col
            md="4"
            class="col-push-12 col-md-4 d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>From </label>
            <b-form-timepicker
              v-model="filters.startTime"
              locale="en"
              class="ml-1"
              placeholder="time"
              reset-button
            />
          </b-col>
          <b-col
            md="4"
            class="col-push-12 col-md-4 d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>To </label>
            <b-form-timepicker
              v-model="filters.endTime"
              min="timevalue"
              locale="en"
              class="ml-1"
              placeholder="time"
              reset-button
            />
          </b-col>
        </b-row>

        <!-- Videos List -->
        <section
          v-if="videoItems !== null && videoItems.length"
          class="grid-view"
        >
          <b-card
            v-for="videoItem in videoItems"
            :id="videoItem.time_camera.replace('T','').replace(' ','').replaceAll('-','').replaceAll(':','')"
            :key="videoItem.time_camera.replace('T','').replace(' ','').replaceAll('-','').replaceAll(':','')"
            no-body
            role="button"
            @click="playVideoFile(videoItem)"
          >
            <b-media
              vertical-align="center"
            >
              <b-overlay
                :show="videoItem.showLoadingOverlay"
                rounded="sm"
                variant="transparent"
                blur="5px"
              >
                <!-- <b-img
                  :alt="`${videoItem.clientname}-${videoItem.camera_id}-${videoItem.sitename}`"
                  fluid
                  class="card-img-top"
                  :src="videoItem.access_url"
                /> -->
                <thumbs-lazy-image-component
                  class="card-img-top"
                  :src="videoItem.access_url"
                  src-placeholder="@/assets/images/noImage.png"
                />
              </b-overlay>
            </b-media>

            <b-card-footer class="pt-1 pb-1 border-dark">
              <small class="text-muted">Recorded On: </small><small>{{ videoItem.time_camera.replace('T', ' ') }}</small><br>
              <div v-if="videoItem.isDownloading">
                <b-badge
                  pill
                  variant="light-warning"
                  class="text-capitalize"
                >
                  Downloading
                </b-badge>
              </div>
              <div v-else-if="(typeof videoItem.video_access_url === undefined || videoItem.video_access_url === null || videoItem.video_access_url === '') && cameraData.is_online">
                <b-badge
                  pill
                  variant="light-danger"
                  class="text-capitalize"
                >
                  Click To Download
                </b-badge>
              </div>
              <div v-else-if="!cameraData.is_online">
                <b-badge
                  pill
                  variant="light-warning"
                  class="text-capitalize"
                >
                  Offline
                </b-badge>
              </div>
              <div v-else>
                <b-badge
                  pill
                  variant="light-success"
                  class="text-capitalize"
                >
                  Downloaded
                </b-badge>
              </div>
            </b-card-footer>
          </b-card>
        </section>
        <!-- No Videos Message  -->
        <div
          v-else
          style="height: inherit"
          class="mt-5 mb-5 text-center"
        >
          <h4>
            <br>
            <br>
            No Videos Found
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
          </h4>
        </div>
        <!-- Pagination -->
        <section
          v-if="videoItems !== null && videoItems.length"
        >
          <b-row>
            <b-col cols="12">
              <b-pagination
                v-model="filters.page"
                :total-rows="totalProducts"
                :per-page="filters.perPage"
                first-number
                align="center"
                last-number
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </section>
      </div>
    </b-overlay>
    <!-- Video Modal -->
    <b-modal
      id="vmodal"
      ref="vmodal"
      ok-only
      ok-title="Close"
      centered
      size="lg"
      :title="videoTitle"
      @close="showVideoModal = false"
    >
      <!-- <b-modal
        v-if="showVideoModal"
        id="modal-video"
        cancel-variant="outline-secondary"
        ok-title="Login"
        cancel-title="Close"
        centered
        :title="videoTitle"
      > -->
      <vue-plyr :options="options">
        <video
          controls
          crossorigin
          playsinline
          :data-poster="videoPoster"
        >
          <source
            size="1080"
            :src="videoSrc"
            type="video/mp4"
          >
          <!-- <track
            default
            kind="captions"
            label="English captions"
            src="/path/to/english.vtt"
            srclang="en"
          > -->
        </video>
      </vue-plyr>
      <b-button
        id="button-video-download"
        variant="primary"
        class="mt-1"
        type="button"
        @click="downloadVideo"
      >
        <feather-icon icon="DownloadIcon" /> Download Video File
      </b-button>
    </b-modal>
    <!-- Take Picture Modal -->
    <b-modal
      id="tpmodal"
      ref="tpmodal"
      ok-title="Show Image"
      cancel-variant="outline-secondary"
      cancel-title="Close"
      centered
      size="lg"
      title="New Content Available"
      @close="showTPModal = false"
    >
      A new image is available. Do you wish to view the image now?
    </b-modal>
  </div>
</template>

<script>
import { Storage } from 'aws-amplify'
// import PubSub from '@aws-amplify/pubsub'

import store from '@/store'
// import axios from 'axios'
import {
  BMedia, BTooltip, BRow, BCol, BCard, BPagination, BCardFooter, BImg, BButton, BFormTimepicker, BOverlay, BBadge, BModal, VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'
import VuePlyr from 'vue-plyr'
import 'vue-plyr/dist/vue-plyr.css'

import { ref } from '@vue/composition-api'
// import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import { useVideosFiltersSortingAndPagination, useCameraMediaData } from './useCameraConsoleVideosList'
import FilmstripSidebar from './Filmstrip-sidebar.vue'
import ThumbsLazyImageComponent from './thumbsLazyImageComponent'
import LazyImageComponent from './lazyImageComponent'
import ability from '../../../../libs/acl/ability'

export default {
  components: {
    BMedia,
    BRow,
    BCol,
    BImg,
    BButton,
    BTooltip,
    vSelect,
    flatPickr,
    BCard,
    BPagination,
    BCardFooter,
    BFormTimepicker,
    FilmstripSidebar,
    BOverlay,
    BBadge,
    BModal,
    // VBModal,
    // Ripple,
    VuePlyr,
    ThumbsLazyImageComponent,
    LazyImageComponent,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    cameraData: {
      type: Object,
      default: null,
    },
    cameraMedia: {
      type: Object,
      default: null,
    },
    showContentItem: {
      type: Object,
      required: true,
    },
    openCalendar: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const now = new Date()
    const maxDateTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59)
    const isFilmstripSidebarActive = ref(false)
    let currentImageDateTime = moment(now).format('YYYY-MM-DDTHH:mm')
    if (props.cameraData.latest_alert_show) {
      currentImageDateTime = props.cameraData.latest_alert.time_created
    } else if (typeof props.cameraData.latest_image !== 'undefined' && typeof props.cameraData.latest_image.access_url !== 'undefined') {
      currentImageDateTime = props.cameraData.latest_image.time_created
    }
    // const dateNtim = '2021-08-18T21:42'
    const currentDateTime = currentImageDateTime
    const displayCurrentImageDateTime = moment(currentImageDateTime).format('MMMM Do YYYY')
    console.log('cameraData')
    console.log(props.cameraData)

    // get the list of content for the camera
    console.log('cameraMedia')
    console.log(props.cameraMedia)

    const statusOptions = [
      { label: 'Active', value: 'active' },
      { label: 'Disabled', value: 'disabled' },
    ]
    const statusFilter = null

    const perPageOptions = [6, 9, 18, 27, 36]

    // ****************************************************************
    // ***** List Related
    const {
      filters,
    } = useVideosFiltersSortingAndPagination()

    const orgFilters = filters

    const videoItems = ref(null)
    const totalProducts = ref(null)
    const { fetchVideoItemsFromMedia } = useCameraMediaData()

    const fetchVideoItems = theCurrentImageDateTime => {
      console.log('fetchVideoItems')
      console.log(currentImageDateTime)
      fetchVideoItemsFromMedia({
        q: filters.value.q,
        page: filters.value.page,
        perPage: filters.value.perPage,
        startTime: filters.value.startTime,
        endTime: filters.value.endTime,
        cameraMedia: props.cameraMedia,
        currentImageDateTime: theCurrentImageDateTime,
      })
        .then(response => {
          console.log('fetchVideoItemsFromMedia response')
          console.log(response)
          videoItems.value = response.videoItems
          totalProducts.value = response.total
        })
    }
    const reFetchViewItems = theCurrentImageDateTime => {
      console.log('reFetchViewItems')
      console.log(orgFilters)
      filters.value.page = 1
      filters.value.startTime = null
      filters.value.endTime = null
      console.log(filters)
      fetchVideoItems(theCurrentImageDateTime)
    }

    const formatDateTimeAMPM = date => {
      const theDate = new Date(date.replace(/-/g, '/'))
      const year = theDate.getFullYear()
      let month = theDate.getMonth() + 1
      if (month < 10) month = `0${month}`
      let day = theDate.getDate()
      if (day < 10) day = `0${day}`
      let hours = theDate.getHours()
      let minutes = theDate.getMinutes()
      const ampm = hours >= 12 ? 'PM' : 'AM'
      hours %= 12
      hours = hours || 12
      minutes = minutes.toString().padStart(2, '0')
      const strTime = `${year}-${month}-${day} ${hours}:${minutes} ${ampm}`
      return strTime
    }

    const formatDateTimeFix = date => {
      const theDate = new Date(date.replace('T', ' ').replaceAll('-', '/'))
      return theDate
    }

    const scrollToTop = () => {
      const rootEle = document.documentElement
      rootEle.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }

    return {
      maxDateTime,
      isFilmstripSidebarActive,

      currentImageDateTime,
      currentDateTime,
      displayCurrentImageDateTime,

      perPageOptions,
      statusFilter,
      statusOptions,
      // video list related items
      fetchVideoItems,
      reFetchViewItems,
      filters,
      videoItems,
      totalProducts,

      formatDateTimeAMPM,
      formatDateTimeFix,
      scrollToTop,
    }
  },
  data() {
    return {
      status: '',
      options: {},
      signalOptions: {},
      theImage: '',
      hadMotionDetected: false,
      isVideoDownloaded: false,
      currentVideoItem: null,
      theImageURL: '',
      theImageFilename: '',
      disableDownload: true,
      disableNextButton: false,
      disablePreviousButton: false,
      timevalue: '',
      timevalue1: '',
      showLoadingOverlay: false,
      picToastcount: 0,
      // Video Player related props
      showVideoModal: false,
      videoTitle: '',
      videoPoster: '',
      videoURL: '',
      videoSrc: '',
      videoDownloadRequestItem: {},
      videoToastcount: 0,
      // Take Picture Modal props
      takePicLast: null,
      showTPModal: false,
      // Auto Refresh
      autoRefreshTimer: null,
      showcheckBtn: false,
    }
  },
  watch: {
    showContentItem() {
      console.log('WATCH: showContentDateTime')
      console.log(this.showContentItem)
      if (this.showContentItem !== null) {
        const getDate = moment(this.showContentItem.time_camera).format('YYYYMMDD')
        this.getCameraMedia('photos', this.cameraData.clientname, getDate, this.cameraData.time_zone, null, null, null, true).then(() => {
          this.setCurrentImage(this.showContentItem)
          console.log(this.showContentItem.video_access_url)
          console.log(this.showContentItem.video_file)
          if ((this.showContentItem.video_access_url !== '' && this.showContentItem.video_access_url !== null) || (typeof this.showContentItem.video_file !== 'undefined' && this.showContentItem.video_file !== '')) {
            this.playVideoFile(this.showContentItem)
          }
        })
      }
    },
  },
  created() {
    // Setup the interface based on alerts and latest image
    this.setupDownloadInfo()

    this.hasNextImage()
    this.hasPreviousImage()
    console.log(this.filters)
    this.$watch(() => this.filters, () => {
      this.fetchVideoItems(this.currentImageDateTime)
    }, {
      deep: true,
    })

    window.addEventListener('keydown', this.doKeyboardCommands)

    this.$emit('contentTabLoaded')

    this.reFetchViewItems(this.currentImageDateTime)
  },
  destroyed() {
    window.removeEventListener('keydown', this.doKeyboardCommands)
  },
  methods: {
    doKeyboardCommands(e) {
      const cmd = String.fromCharCode(e.keyCode).toLowerCase()
      // Next Previous
      if (cmd === '%') {
        this.showPreviousImage()
      } else if (cmd === "'") {
        this.showNextImage()
      }
    },
    setupDownloadInfo() {
      console.log('setupDownloadInfo')
      if (this.cameraData.latest_alert_show) {
        console.log('Use Alert Image')
        this.getImage(this.cameraData.latest_alert.access_url)
        this.hadMotionDetected = true
        this.isVideoDownloaded = false
        this.disableDownload = false
        this.theImageFilename = `${this.cameraData.sitename}-${this.cameraData.location_name}-${this.cameraData.latest_alert.time_created}.jpg`
        this.acknowledgeAlert()
      } else if (typeof this.cameraData.latest_image !== 'undefined' && typeof this.cameraData.latest_image.access_url !== 'undefined' && this.cameraData.latest_image.access_url !== '') {
        console.log('Use Latest Image')
        this.getImage(this.cameraData.latest_image.access_url)
        this.hadMotionDetected = false
        this.isVideoDownloaded = false
        this.disableDownload = false
        this.theImageFilename = `${this.cameraData.sitename}-${this.cameraData.location_name}-${this.cameraData.latest_image.time_created}.jpg`
      } else {
        console.log('Use No Image')
        this.theImage = ''
        this.hadMotionDetected = false
        this.isVideoDownloaded = false
        this.disableDownload = true
        this.theImageFilename = ''
      }
    },
    getCameraMedia(mediaType, clientname, getDate, timeZone, startTime, endTime, startKey, forceRefresh) {
      console.log('getCameraMedia')
      const now = new Date()
      this.maxDateTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59)
      return new Promise((resolve, reject) => {
        if (ability.can('read', 'cameras') || ability.can('read', 'camerasmanagement')) {
          this.disablePreviousButton = true
          const qvars1 = {}
          qvars1.camera_id = this.cameraData.camera_id
          qvars1.media_type = mediaType
          qvars1.clientname = clientname
          qvars1.get_date = getDate
          qvars1.time_zone = timeZone
          // Removed to force full day data
          // if (startTime !== '' && startTime !== null) {
          //   qvars1.start_time = startTime
          // }
          // if (endTime !== '' && endTime !== null) {
          //   qvars1.end_time = endTime
          // }
          // if (startKey !== '' && startKey !== null) {
          //   const theStartKey = {}
          //   theStartKey.PK = startKey.PK
          //   theStartKey.SK = startKey.SK
          //   qvars1.start_key = theStartKey
          // }
          console.log(qvars1)
          // Get the list of images for the camera
          this.showLoadingOverlay = true
          store
            .dispatch('app-cameraconsole/fetchCameraMedia', qvars1)
            .then(response1 => {
              console.log('app-cameraconsole/fetchCameraMedia')
              console.log(response1)
              // Check to see if the getDate matches the current date and add new items or replace data
              if (forceRefresh === true) {
                console.log('Force Refresh camera media data')
                this.cameraMedia.Count = response1.Count
                this.cameraMedia.Items = response1.Items
                console.log(this.cameraMedia)
              } else if (getDate === moment(this.currentImageDateTime).format('YYYYMMDD') && startTime !== '' && startTime !== null && startTime === moment(this.currentImageDateTime).format('HH:mm')) {
                console.log('Add to start of camera media data')
                // add the data
                const tmpArr = []
                let foundItem = false
                response1.Items.forEach(item => {
                  tmpArr.forEach(tmpItem1 => {
                    if (tmpItem1.time_camera === item.time_camera) {
                      foundItem = true
                    }
                  })
                  if (!foundItem) {
                    tmpArr.push(item)
                  }
                  foundItem = false
                })
                foundItem = false
                this.cameraMedia.Items.forEach(item2 => {
                  tmpArr.forEach(tmpItem2 => {
                    if (tmpItem2.time_camera === item2.time_camera) {
                      foundItem = true
                    }
                  })
                  if (!foundItem) {
                    tmpArr.push(item2)
                  }
                  foundItem = false
                })
                this.cameraMedia.Items = tmpArr
                // response1.Items.slice().reverse().forEach(item => {
                //   this.cameraMedia.Items.unshift(item)
                // })
                this.cameraMedia.Count += tmpArr.length
              } else if (startKey !== '' && startKey !== null) {
                console.log('Add to end of camera media data')
                // add the data
                const tmpArr = []
                let foundItem = false
                this.cameraMedia.Items.forEach(item2 => {
                  tmpArr.forEach(tmpItem1 => {
                    if (tmpItem1.time_camera === item2.time_camera) {
                      foundItem = true
                    }
                  })
                  if (!foundItem) {
                    tmpArr.push(item2)
                  }
                  foundItem = false
                })
                foundItem = false
                response1.Items.forEach(item => {
                  tmpArr.forEach(tmpItem1 => {
                    if (tmpItem1.time_camera === item.time_camera) {
                      foundItem = true
                    }
                  })
                  if (!foundItem) {
                    tmpArr.push(item)
                  }
                  foundItem = false
                })
                this.cameraMedia.Items = tmpArr
                this.cameraMedia.Count = tmpArr.length
              } else {
                console.log('Replace camera media data')
                this.cameraMedia.Count = response1.Count
                this.cameraMedia.Items = response1.Items
              }
              this.cameraMedia.LastEvaluatedKey = response1.LastEvaluatedKey
              this.cameraMedia.ScannedCount = response1.ScannedCount
              this.disablePreviousButton = false
              console.log(this.cameraMedia)
              const successObject = {
                msg: 'Success',
                data: this.cameraMedia,
              }
              resolve(successObject)
              this.hasNextImage()
              this.hasPreviousImage()
              this.showLoadingOverlay = false
            })
            .catch(error => {
              console.log(error)
              this.showLoadingOverlay = false
              const errorObject = {
                msg: 'An error occured',
                error,
              }
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'An error occured getting media data.',
                  icon: 'CameraIcon',
                  variant: 'danger',
                },
              })
              this.currentDateTime = this.currentImageDateTime
              reject(errorObject)
            })
        }
      })
    },
    handleFilmstripGetCameraMedia(qvars) {
      console.log('handleFilmstripGetCameraMedia')
      return new Promise(resolve => {
        this.getCameraMedia(qvars.mediaType, qvars.clientname, qvars.getDate, qvars.timeZone, qvars.startTime, qvars.endTime, qvars.startKey, qvars.forceRefresh).then(result => {
          resolve(result)
        })
      })
    },
    getVideoMedia() {
      console.log('getVideoMedia')
      if (ability.can('read', 'cameras') || ability.can('read', 'camerasmanagement')) {
        const theDate = new Date(this.currentImageDateTime)
        const getDate = moment(theDate).format('YYYYMMDD')
        const qvars1 = {}
        qvars1.camera_id = this.cameraData.camera_id
        qvars1.media_type = 'video'
        qvars1.clientname = this.cameraData.clientname
        qvars1.get_date = getDate
        qvars1.time_zone = this.cameraData.time_zone
        store
          .dispatch('app-cameraconsole/fetchCameraMedia', qvars1)
          .then(response1 => {
            console.log('app-cameraconsole/fetchCameraMedia')
            console.log(response1)
            // Add videoDownloaded property to each camera media item based on videos available
            // this.cameraMedia.Items.forEach(item => {
            //   item.videoDownloaded = false
            //   if (typeof item.video_file !== 'undefined' && item.video_file !== null && item.video_file !== '') {

            //   }
            // })
          })
      }
    },
    getImage(img) {
      console.log('getImage')
      if (ability.can('read', 'cameras') || ability.can('read', 'camerasmanagement')) {
        this.theImageURL = img
        if (img.indexOf('public/')) {
          this.theImageURL = img.slice(img.indexOf('public/') + 7)
        } else if (img.indexOf('Public')) {
          this.theImageURL = img.slice(img.indexOf('Public/') + 7)
        } else if (img.indexOf('PUBLIC')) {
          this.theImageURL = img.slice(img.indexOf('PUBLIC/') + 7)
        }
        Storage.get(this.theImageURL, { download: false }).then(result1 => {
          this.theImage = result1
        })
      }
    },
    setCurrentImage(item) {
      console.log('setCurrentImage')
      console.log(item)
      return new Promise((resolve, reject) => {
        try {
          if (typeof item !== 'undefined' && typeof item.time_camera !== 'undefined' && typeof item.access_url !== 'undefined') {
            const itemDateTime = new Date(item.time_camera)
            const currDateTime = new Date(this.currentImageDateTime)
            // Set the various dynamic vars for the new current image
            this.currentImageDateTime = item.time_camera
            this.displayCurrentImageDateTime = moment(this.formatDateTimeFix(this.currentImageDateTime)).format('MMMM Do, YYYY')
            this.currentDateTime = this.currentImageDateTime
            this.theImageURL = item.access_url
            // Use Amplify API to get the pre-signed URL
            this.getImage(this.theImageURL)
            // Determine if image is assocated to motion detection
            if (item.video_file !== undefined && item.video_file !== '') {
              this.hadMotionDetected = true
              this.currentVideoItem = item
              this.isVideoDownloaded = false
              if (item.video_access_url !== undefined && item.video_access_url !== '') {
                this.isVideoDownloaded = true
              }
            } else {
              this.hadMotionDetected = false
              this.isVideoDownloaded = false
              this.currentVideoItem = null
            }
            // Setup download for current image
            this.disableDownload = false
            this.theImageFilename = `${this.cameraData.sitename}-${this.cameraData.location_name}-${this.currentImageDateTime}.jpg`
            // Check to see if we are moving to a new day
            if (moment(currDateTime).format('YYYYMMDD') !== moment(itemDateTime).format('YYYYMMDD')) {
              // Re-init videos list
              this.reFetchViewItems(item.time_camera)
            }
          }
          console.log(`setCurrentImage: ${this.currentImageDateTime}`)
          resolve(true)
        } catch (err) {
          reject(err)
        }
        this.hasNextImage()
        this.hasPreviousImage()
      })
    },
    handleFilmstripSetCurrentImage(item) {
      this.setCurrentImage(item)
    },
    handleFilmstripClick(item) {
      console.log('handleFilmstripClick')
      this.setCurrentImage(item)
    },
    hasNextImage() {
      // Determine if there is a next image in the media list and set the next button
      console.log('hasNextImage')
      let shouldSkip = false
      if (this.cameraMedia !== null && this.cameraMedia.Items !== null && this.cameraMedia.Items.length > 0) {
        this.cameraMedia.Items.forEach((item, index) => {
          if (shouldSkip) {
            return
          }
          let itemDateTime = new Date(item.time_camera)
          let currDateTime = new Date(this.currentImageDateTime)
          itemDateTime = itemDateTime.getTime()
          currDateTime = currDateTime.getTime()
          if (itemDateTime === currDateTime) {
            if (index > 0) {
              this.setNextButtonToEnabled()
            } else {
              this.setNextButtonToDisabled()
            }
            shouldSkip = true
          }
        })
      } else {
        this.disableNextButton = true
      }
    },
    hasPreviousImage() {
      // Determine if there is a previous image in the media list and set the previous button
      console.log('hasPreviousImage')
      let shouldSkip = false
      if (this.cameraMedia !== null && this.cameraMedia.Items !== null && this.cameraMedia.Items.length > 0) {
        this.cameraMedia.Items.forEach((item, index) => {
          if (shouldSkip) {
            return
          }
          let itemDateTime = new Date(item.time_camera)
          let currDateTime = new Date(this.currentImageDateTime)
          itemDateTime = itemDateTime.getTime()
          currDateTime = currDateTime.getTime()
          if (itemDateTime === currDateTime) {
            if (index < this.cameraMedia.Items.length - 1) {
              this.disablePreviousButton = false
            } else {
              this.disablePreviousButton = true
            }
            shouldSkip = true
          }
        })
      } else {
        this.disablePreviousButton = true
      }
    },
    showNextImage() {
      // Show the next image in the camera media list
      console.log('showNextImage')
      const now = new Date()
      this.maxDateTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59)
      let shouldSkip = false
      if (this.cameraMedia !== null && this.cameraMedia.Items !== null && this.cameraMedia.Items.length > 0) {
        this.cameraMedia.Items.forEach((item, index) => {
          if (shouldSkip) {
            return
          }
          let itemDateTime = new Date(item.time_camera)
          let currDateTime = new Date(this.currentImageDateTime)
          itemDateTime = itemDateTime.getTime()
          currDateTime = currDateTime.getTime()
          if (itemDateTime === currDateTime) {
            // Found the current image, now show the next one
            if (index - 1 >= 0) {
              this.setCurrentImage(this.cameraMedia.Items[index - 1])
              console.log(`Setting image to: ${index - 1}`)
            }
            if (index - 1 <= 0) {
              // Use this Process if data pagination is used
              // // Check to see if there are newer images
              // const getDate = moment(this.currentImageDateTime).format('YYYYMMDD')
              // const startDate = moment(this.currentImageDateTime).format('HH:mm')
              // this.getCameraMedia('photos', this.cameraData.clientname, getDate, this.cameraData.time_zone, startDate, '', null, false).then(response => {
              //   console.log(response)
              //   if (response.data.ScannedCount > 1) {
              //     this.setNextButtonToEnabled()
              //   } else {
              //     this.disableNextButton = true
              //   }
              // })
              // Use this Process if data is per day
              let theDate = new Date(this.currentImageDateTime)
              theDate.setDate(theDate.getDate() + 1)
              theDate.setHours(0, 0, 0, 0)
              console.log(theDate)
              const nowDate = new Date()
              nowDate.setHours(0, 0, 0, 0)
              console.log(nowDate)
              if (theDate.getTime() > nowDate.getTime()) {
                theDate = new Date(this.currentImageDateTime)
              }
              const getDate = moment(theDate).format('YYYYMMDD')
              const startDate = moment(this.currentImageDateTime).format('HH:mm')
              this.getCameraMedia('photos', this.cameraData.clientname, getDate, this.cameraData.time_zone, startDate, null, false).then(response => {
                // console.log(response)
                if (Date.parse(response.data.Items[0].time_camera.replace('T', ' ')) > Date.parse(this.currentImageDateTime)) {
                  this.setNextButtonToEnabled()
                } else {
                  this.setNextButtonToDisabled()
                }
              })
            }
            shouldSkip = true
          }
        })
      } else {
        this.setNextButtonToDisabled()
      }
      this.hasNextImage()
      this.hasPreviousImage()
    },
    showPreviousImage() {
      // Show the previous image in the camera media list
      console.log('showPreviousImage')
      const now = new Date()
      this.maxDateTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59)
      let shouldSkip = false
      if (this.cameraMedia !== null && this.cameraMedia.Items !== null && this.cameraMedia.Items.length > 0) {
        this.cameraMedia.Items.forEach((item, index) => {
          if (shouldSkip) {
            return
          }
          let itemDateTime = new Date(item.time_camera)
          let currDateTime = new Date(this.currentImageDateTime)
          itemDateTime = itemDateTime.getTime()
          currDateTime = currDateTime.getTime()
          if (itemDateTime === currDateTime) {
            console.log('found previous image')
            shouldSkip = true
            // Found the current image, now show the next one
            this.setCurrentImage(this.cameraMedia.Items[index + 1])
            console.log(`Setting image to: ${index + 1}`)

            // Use this Process if data pagination is used
            // if (index === this.cameraMedia.Items.length - 1) {
            //   // We are at the end of the list. Check if there is any more images to load for today
            //   if (typeof this.cameraMedia.LastEvaluatedKey !== 'undefined'
            //     && typeof this.cameraMedia.LastEvaluatedKey.PK !== 'undefined'
            //     && typeof this.cameraMedia.LastEvaluatedKey.SK !== 'undefined'
            //     && this.cameraMedia.LastEvaluatedKey.PK !== ''
            //     && this.cameraMedia.LastEvaluatedKey.SK !== ''
            //   ) {
            //     // Get the next set of images and add to the cameraMedia
            //     const getDate = moment(this.currentImageDateTime).format('YYYYMMDD')
            //     this.getCameraMedia('photos', this.cameraData.clientname, getDate, this.cameraData.time_zone, '', '', this.cameraMedia.LastEvaluatedKey, false).then(() => {
            //       this.hasPreviousImage()
            //     })
            //   }
            // }

            // Use this Process if data is per day
            console.log(index + 1)
            console.log(this.cameraMedia.Items.length)
            if (index + 1 === this.cameraMedia.Items.length - 1) {
              const theDate = new Date(this.currentImageDateTime)
              theDate.setDate(theDate.getDate() - 1)
              console.log(theDate)
              const getDate = moment(theDate).format('YYYYMMDD')
              this.getCameraMedia('photos', this.cameraData.clientname, getDate, this.cameraData.time_zone, '', '', this.cameraMedia.LastEvaluatedKey, false).then(() => {
                this.hasPreviousImage()
              })
            }
            this.setNextButtonToEnabled()
          }
        })
      } else {
        this.disablePreviousButton = true
        this.setNextButtonToEnabled()
        this.hasPreviousImage()
      }
    },
    showLatestImage() {
      console.log('showLatestImage')
      const now = new Date()
      const getDate = moment(now).format('YYYY-MM-DDTHH:mm:ss')
      this.maxDateTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59)
      console.log('this.maxDateTime')
      console.log(this.maxDateTime)
      this.handleFileStripSetCurrentDateTime(getDate)
    },
    setNextButtonToDisabled() {
      this.disableNextButton = true
      // Determine if the camera is set to take images today and when the next image is coming
      const now = new Date()
      const todayDay = now.getDay()
      if (this.cameraData.schedule[todayDay].interval !== ''
        && Number(this.cameraData.schedule[todayDay].interval) > 0
        && this.cameraData.schedule[todayDay].start_time !== ''
        && this.cameraData.schedule[todayDay].end_time !== ''
      ) {
        const startSchedule = new Date()
        const startTime = this.cameraData.schedule[todayDay].start_time.split(':')
        startSchedule.setHours(startTime[0], startTime[1], 0)
        const endSchedule = new Date()
        const endTime = this.cameraData.schedule[todayDay].end_time.split(':')
        endSchedule.setHours(endTime[0], endTime[1], 0)
        const theCurrentImageDateTime = new Date(this.currentImageDateTime.replace('T', ' '))
        if (now >= startSchedule && now < endSchedule
          && Date.parse(this.currentImageDateTime.replace('T', ' ')) === Date.parse(this.cameraMedia.Items[0].time_camera.replace('T', ' '))
          && theCurrentImageDateTime.getFullYear() === now.getFullYear()
          && theCurrentImageDateTime.getMonth() === now.getMonth()
          && theCurrentImageDateTime.getDate() === now.getDate()
        ) {
          // Start a time based on image interval to get the new image
          const latestImageDateTime = new Date(this.cameraMedia.Items[0].time_camera.replace('T', ' '))
          const intervalMilliseconds = Number(this.cameraData.schedule[todayDay].interval) * 60 * 1000
          const diffMilliSec = now.getTime() - latestImageDateTime.getTime()
          const timeoutMilliseconds = intervalMilliseconds - diffMilliSec + 60000
          console.log(timeoutMilliseconds)
          clearTimeout(this.autoRefreshTimer)
          this.autoRefreshTimer = setTimeout(() => {
            this.setNextButtonToEnabled()
          }, timeoutMilliseconds)
        }
      }
    },
    setNextButtonToEnabled() {
      this.disableNextButton = false
      clearTimeout(this.autoRefreshTimer)
    },
    onCloseImageDateTime() {
      console.log('onCloseImageDateTime')
      console.log(this.cameraMedia)
      const currentDateTimeFormated = this.formatDateTimeFix(this.currentDateTime)
      console.log(this.currentDateTime)
      console.log(currentDateTimeFormated)

      let firstCameraMediaItemEPOCH = 0
      let lastCameraMediaItemEpoch = 0
      let currentDateTimeEPOCH = 0
      this.showcheckBtn = false

      try {
        // const now = new Date()
        // console.log(now)
        // console.log(currentDateTimeFormated)
        // console.log(currentDateTimeFormated < now)
        // console.log(this.formatDateTimeFix(this.cameraMedia.Items[0].time_camera))
        // console.log(this.formatDateTimeFix(this.cameraMedia.Items[this.cameraMedia.Items.length - 1].time_camera))
        // console.log(new Date(this.cameraMedia.Items[0].time_camera))
        // console.log(new Date(this.cameraMedia.Items[this.cameraMedia.Items.length - 1].time_camera))
        // console.log(new Date(currentDateTimeFormated))
        firstCameraMediaItemEPOCH = new Date(this.cameraMedia.Items[0].time_camera).getTime()
        lastCameraMediaItemEpoch = new Date(this.cameraMedia.Items[this.cameraMedia.Items.length - 1].time_camera).getTime()
        currentDateTimeEPOCH = new Date(currentDateTimeFormated).getTime()
        console.log(firstCameraMediaItemEPOCH)
        console.log(lastCameraMediaItemEpoch)
        console.log(currentDateTimeEPOCH)
        console.log(this.cameraMedia.Items.length > 0)
        console.log(firstCameraMediaItemEPOCH >= currentDateTimeEPOCH)
        console.log(lastCameraMediaItemEpoch <= currentDateTimeEPOCH)
      // eslint-disable-next-line no-empty
      } catch (e) {
        console.log('onCloseImageDateTime Catch Error')
        console.log(e)
      }
      // First check to see if the date/time selected is within the current camera media dataset
      if (this.cameraMedia.Items.length > 0 && firstCameraMediaItemEPOCH >= currentDateTimeEPOCH && lastCameraMediaItemEpoch <= currentDateTimeEPOCH) {
        // the date/time selected has already been loaded in the current camera media dataset
        console.log('in the current camera media dataset')
        let shouldSkip = false
        this.cameraMedia.Items.forEach((item, index) => {
          if (shouldSkip) {
            return
          }
          const itemDateTime = new Date(item.time_camera)
          const currDateTime = new Date(this.formatDateTimeFix(this.currentDateTime))
          const itemDateTimeEPOCH = itemDateTime.getTime()
          const currDateTimeEPOCH = currDateTime.getTime()
          if (itemDateTimeEPOCH <= currDateTimeEPOCH || index === this.cameraMedia.Items.length - 1) {
            // found the first image that is less than or equal to the date/time selected
            shouldSkip = true
            this.setCurrentImage(item)
          }
        })
      } else {
        // Get an updated list of camera media
        // Use this Process if data pagination is used
        // console.log('Get an updated list of camera media')
        // const getDate = moment(this.currentDateTime).format('YYYYMMDD')
        // const endTime = moment(this.currentDateTime).format('HH:mm')
        // console.log(getDate)
        // console.log(endTime)
        // this.getCameraMedia('photos', this.cameraData.clientname, getDate, this.cameraData.time_zone, null, endTime, null, true).then(() => {
        //   this.setCurrentImage(this.cameraMedia.Items[0])
        // })

        // Use this Process if data is per day
        console.log('Get an updated list of camera media')
        const getDate = moment(currentDateTimeFormated).format('YYYYMMDD')
        console.log(getDate)
        if (getDate !== 'Invalid date') {
          this.getCameraMedia('photos', this.cameraData.clientname, getDate, this.cameraData.time_zone, null, null, null, true).then(() => {
            let shouldSkip = false
            this.fetchVideoItems(this.currentImageDateTime)
            this.cameraMedia.Items.forEach((item, index) => {
              if (shouldSkip) {
                return
              }
              let itemDateTime = new Date(item.time_camera)
              let currDateTime = new Date(this.formatDateTimeFix(this.currentDateTime))
              itemDateTime = itemDateTime.getTime()
              currDateTime = currDateTime.getTime()
              if (itemDateTime <= currDateTime || index === this.cameraMedia.Items.length - 1) {
                // found the first image that is less than or equal to the date/time selected
                shouldSkip = true
                this.setCurrentImage(item)
              }
            })
          })
        }
      }
    },
    handleFileStripSetCurrentDateTime(theDateTime) {
      console.log('handleFileStripSetCurrentDateTime')
      const finalDateTime = theDateTime.replace('T', ' ')
      console.log(finalDateTime)
      console.log(typeof finalDateTime)
      const getDate = moment(this.finalDateTime).format('YYYYMMDD')
      if (getDate !== 'Invalid date') {
        this.currentDateTime = finalDateTime
        this.onCloseImageDateTime()
      }
    },
    takePicture() {
      // Call API to tell camera to take picture
      console.log('takePicture')
      if (ability.can('create', 'cameras') || ability.can('create', 'camerasmanagement')) {
        const now = new Date()
        if (this.takePicLast === null || now.getTime() - this.takePicLast.getTime() > 30000) {
          this.takePicLast = new Date()
          const qvars1 = {
            camera_id: this.cameraData.camera_id,
            command: 'TAKE_PICTURE',
          }

          store
            .dispatch('app-cameraconsole/commandCamera', qvars1)
            .then(response1 => {
              console.log('app-cameraconsole/commandCamera')
              console.log(response1)
              // TODO: Need to add AWS IoT listening to know when the picture is taken and refresh the main image with the new image
              // Also need to get an updated list of image media for the camera if past a certain time
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Command sent to camera. You will be notified when image is retreived.',
                  icon: 'CameraIcon',
                  variant: 'warning',
                },
              })
            })
            .catch(error => {
              console.log(error)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `Error sending command to camera. ${error}`,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Warning: Take Picture is restricted to once per 30 seconds.',
              icon: 'AlertTriangleIcon',
              variant: 'warning',
            },
          })
        }
      }
    },
    onPictureTaken(imageDT) {
      console.log('onPictureTaken')
      console.log(imageDT)
      this.setNextButtonToEnabled()

      // create the Toast notification
      // Use a shorter name for `this.$createElement`
      const h = this.$createElement
      // Create a ID with a incremented count
      this.picToastcount += 1
      const theId = `pic-toast-${this.picToastcount}`

      // Create the custom message
      const vNodesMsg = h(
        'div', [
          h('b-row',
            [
              h('b-col', { class: ['col-3', 'text-right', 'pr-0'] },
                [
                  h('small', 'Site:'),
                ]),
              h('b-col', { class: ['col-9'] },
                [
                  h('strong', this.cameraData.sitename),
                ]),
            ]),
          h('b-row',
            [
              h('b-col', { class: ['col-3', 'text-right', 'pr-0'] },
                [
                  h('small', 'Location:'),
                ]),
              h('b-col', { class: ['col-9'] },
                [
                  h('strong', this.cameraData.location_name),
                ]),
            ]),
          h('b-row', { class: ['col-12', 'text-right', 'mt-1'] },
            [
              h('b-button', {
                props: {
                  variant: 'primary',
                },
                on: {
                  click: () => {
                    this.$bvToast.hide(theId)
                    this.$emit('showContentTab')
                    this.currentDateTime = this.formatDateTimeFix(imageDT)
                    console.log('Get an updated list of camera media')
                    const getDate = moment(this.currentDateTime).format('YYYYMMDD')
                    console.log(getDate)
                    this.getCameraMedia('photos', this.cameraData.clientname, getDate, this.cameraData.time_zone, null, null, null, true).then(() => {
                      let shouldSkip = false
                      this.fetchVideoItems(this.currentImageDateTime)
                      this.cameraMedia.Items.forEach(item => {
                        if (shouldSkip) {
                          return
                        }
                        let itemDateTime = new Date(this.formatDateTimeFix(item.time_camera))
                        let currDateTime = new Date(this.formatDateTimeFix(imageDT))
                        itemDateTime = itemDateTime.getTime()
                        currDateTime = currDateTime.getTime()
                        if (itemDateTime === currDateTime) {
                          // found the first image that is less than or equal to the date/time selected
                          shouldSkip = true
                          this.setCurrentImage(item)
                        }
                      })
                    })
                  },
                },
              }, 'Show Latest Image'),
            ]),
        ],
      )
      // Create the title
      const vNodesTitle = h(
        'div',
        { class: ['d-flex', 'flex-grow-1', 'align-items-baseline', 'mr-2'] },
        [
          h('strong', { class: 'mr-2' }, 'New Image Available'),
          // h('small', { class: 'ml-auto text-italics' }, '5 minutes ago'),
        ],
      )
      // Create the toast
      this.$bvToast.toast([vNodesMsg], {
        id: theId,
        title: [vNodesTitle],
        // noCloseButton: true,
        solid: true,
        // variant: 'primary',
        noAutoHide: true,
      })
    },
    downloadImage() {
      console.log('downloadImage')
      if (ability.can('read', 'cameras') || ability.can('read', 'camerasmanagement')) {
        const result = Storage.get(this.theImageURL, { download: true }).then(result1 => {
          console.log(result1)
          console.log(result)
          const fileUrl = window.URL.createObjectURL(result1.Body)
          const fileLink = document.createElement('a')
          fileLink.href = fileUrl
          fileLink.setAttribute('download', this.theImageFilename)
          document.body.appendChild(fileLink)
          fileLink.click()
        })
      }
    },
    playVideoFile(videoItem) {
      console.log('playVideoFile')
      if (ability.can('read', 'cameras') || ability.can('read', 'camerasmanagement')) {
        console.log(videoItem)
        // First show the image in the main image window
        this.currentDateTime = this.formatDateTimeFix(videoItem.time_camera)
        this.setCurrentImage(videoItem)
        this.scrollToTop()
        // Determine if video has already been downloaded Based on video_access_url property
        if (videoItem.video_access_url !== '' && videoItem.video_access_url !== null) {
          console.log('play Video File')
          // Play the video in the video player
          // First set the scene properties
          this.videoTitle = `${this.cameraData.sitename} -- ${this.cameraData.location_name} -- ${videoItem.time_camera.replace('T', ' ')}`
          this.videoPoster = videoItem.thumb_url
          const vid = videoItem.video_access_url
          this.videoURL = ''
          try {
            if (vid.indexOf('public/')) {
              this.videoURL = vid.slice(vid.indexOf('public/') + 7)
            } else if (vid.indexOf('Public')) {
              this.videoURL = vid.slice(vid.indexOf('Public/') + 7)
            } else if (vid.indexOf('PUBLIC')) {
              this.videoURL = vid.slice(vid.indexOf('PUBLIC/') + 7)
            }
          } catch (e) {
            console.log(e)
          }
          Storage.get(this.videoURL, { download: false }).then(result1 => {
            console.log('play Video File: GOT RESULT')
            console.log(result1)
            this.videoSrc = result1
            // Open player modal
            this.showVideoModal = true
            console.log(`showVideoModal: ${this.showVideoModal}`)
            // eslint-disable-next-line no-undef
            console.log(this.$refs.vmodal)
            this.$refs.vmodal.show()
          })
        } else if (videoItem.isDownloading !== true) {
          this.$bvModal
            .msgBoxConfirm('Do you want to download the video from the camera to the cloud?', {
              title: 'Download Video',
              size: 'sm',
              okVariant: 'primary',
              okTitle: 'Yes',
              cancelTitle: 'No',
              cancelVariant: 'outline-secondary',
              hideHeaderClose: false,
              centered: true,
            })
            .then(value => {
              if (value) {
                // Request the video be downloaded
                this.videoDownloadRequestItem = videoItem
                this.videoItems.forEach((item, index) => {
                  if (videoItem.video_file === item.video_file) {
                    const tmp = item
                    tmp.showLoadingOverlay = true
                    tmp.isDownloading = true
                    tmp.video_access_url = null
                    this.videoItems[index] = tmp
                  }
                })
                const qvars2 = {
                  camera_id: this.cameraData.camera_id,
                  command: 'RETRIEVE_VIDEO',
                  video_file: videoItem.video_file,
                }

                store
                  .dispatch('app-cameraconsole/commandCamera', qvars2)
                  .then(response1 => {
                    console.log('app-cameraconsole/commandCamera')
                    console.log(response1)
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: 'Command sent to camera. You will be notified when video is retreived.',
                        icon: 'CameraIcon',
                        variant: 'warning',
                      },
                    })
                    // Need to wait for backend to tell us that the video has been downloaded
                  })
                  .catch(error => {
                    console.log(error)
                    // eslint-disable-next-line no-param-reassign
                    this.videoItems.forEach((item, index) => {
                      if (videoItem.video_file === item.video_file) {
                        const tmp = item
                        tmp.showLoadingOverlay = false
                        tmp.isDownloading = false
                        tmp.video_access_url = ''
                        this.videoItems[index] = tmp
                      }
                    })
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: `Error sending command to camera. ${error}`,
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                      },
                    })
                  })
              }
            })
        } else if (videoItem.isDownloading === true) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Video Already Downloading',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      }
    },
    onVideoDownloaded(videoAccessUrl) {
      console.log('onVideoDownloaded')
      let videoFile = videoAccessUrl.slice(videoAccessUrl.indexOf('videos/') + 7)
      videoFile = videoFile.replace('mp4', 'avi')
      this.videoItems.forEach((item, index) => {
        if (item.video_file === videoFile) {
          const tmp = item
          tmp.video_access_url = videoAccessUrl
          tmp.showLoadingOverlay = false
          tmp.isDownloading = false
          this.videoItems[index] = tmp
        }
      })
      this.cameraMedia.Items.forEach((item, index) => {
        if (item.video_file === videoFile) {
          const tmp = item
          tmp.video_access_url = videoAccessUrl
          tmp.showLoadingOverlay = false
          tmp.isDownloading = false
          this.videoItems[index] = tmp
        }
      })
      this.fetchVideoItems(this.currentImageDateTime)
      let shouldSkip = false
      this.videoItems.forEach(item => {
        if (shouldSkip) {
          return
        }
        if (item.video_file === videoFile) {
          // create the Toast notification
          // Use a shorter name for `this.$createElement`
          const h = this.$createElement
          // Create a ID with a incremented count
          this.videoToastcount += 1
          const theId = `videoDownload-toast-${this.videoToastcount}`

          // Create the custom message
          const vNodesMsg = h(
            'div', [
              h('b-row',
                [
                  h('b-col', { class: ['col-3', 'text-right', 'pr-0'] },
                    [
                      h('small', 'Site:'),
                    ]),
                  h('b-col', { class: ['col-9'] },
                    [
                      h('strong', this.cameraData.sitename),
                    ]),
                ]),
              h('b-row',
                [
                  h('b-col', { class: ['col-3', 'text-right', 'pr-0'] },
                    [
                      h('small', 'Location:'),
                    ]),
                  h('b-col', { class: ['col-9'] },
                    [
                      h('strong', this.cameraData.location_name),
                    ]),
                ]),
              h('b-row',
                [
                  h('b-col', { class: ['col-3', 'text-right', 'pr-0'] },
                    [
                      h('small', 'Video Date:'),
                    ]),
                  h('b-col', { class: ['col-9'] },
                    [
                      h('strong', item.time_camera.replace('T', ' ')),
                    ]),
                ]),
              h('b-row', { class: ['col-12', 'text-right', 'mt-1'] },
                [
                  h('b-button', {
                    props: {
                      variant: 'primary',
                    },
                    on: {
                      click: () => {
                        this.$emit('showContentTab')
                        this.playDownloadedVideoFile(videoFile, videoAccessUrl)
                        this.$bvToast.hide(theId)
                      },
                    },
                  }, 'Play Video'),
                ]),
            ],
          )
          // Create the title
          const vNodesTitle = h(
            'div',
            { class: ['d-flex', 'flex-grow-1', 'align-items-baseline', 'mr-2'] },
            [
              h('strong', { class: 'mr-2' }, 'Video Downloaded'),
              // h('small', { class: 'ml-auto text-italics' }, '5 minutes ago'),
            ],
          )
          // Create the toast
          this.$bvToast.toast([vNodesMsg], {
            id: theId,
            title: [vNodesTitle],
            // noCloseButton: true,
            solid: true,
            // variant: 'primary',
            noAutoHide: true,
          })

          shouldSkip = true
        }
      })
    },
    playDownloadedVideoFile(videoFile, videoAccessUrl) {
      console.log('playDownloadedVideoFile')
      this.$emit('showContentTab')
      this.videoItems.forEach(item => {
        if (item.video_file === videoFile) {
          const tmp = item
          tmp.video_access_url = videoAccessUrl
          this.playVideoFile(tmp)
        }
      })
    },
    downloadVideo() {
      console.log('downloadVideo')
      if (ability.can('read', 'cameras') || ability.can('read', 'camerasmanagement')) {
        const result = Storage.get(this.videoURL, { download: true }).then(result1 => {
          console.log(result1)
          console.log(result)
          const fileUrl = window.URL.createObjectURL(result1.Body)
          const fileLink = document.createElement('a')
          fileLink.href = fileUrl
          fileLink.setAttribute('download', `${this.videoTitle}.mp4`)
          document.body.appendChild(fileLink)
          fileLink.click()
        })
      }
    },
    acknowledgeAlert() {
      console.log('acknowledgeAlert')
      const qvars = {
        camera_id: this.cameraData.camera_id,
        latest: 'alert',
      }
      store
        .dispatch('app-cameraconsole/toggleLatest', qvars)
        .then(response1 => {
          console.log('app-cameraconsole/toggleLatest')
          console.log(response1)
          this.cameraData.latest_alert_show = false
        })
        .catch(error => {
          console.log(error)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occured acknowledging alert.',
              icon: 'CameraIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import "@core/scss/base/pages/app-ecommerce.scss";
</style>
