var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$can('create', 'cameras') || _vm.$can('create', 'camerasmanagement'))?_c('b-sidebar',{attrs:{"id":"add-new-timelapse-sidebar","visible":_vm.isAddNewTimelapseSidebarActive,"bg-variant":"white","sidebar-class":"sidebar-lg","shadow":"","backdrop":"","no-header":"","right":""},on:{"hidden":_vm.resetForm,"change":function (val) { return _vm.$emit('update:is-add-new-timelapse-sidebar-active', val); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Add New Timelapse ")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.validateForm)},"reset":function($event){$event.preventDefault();return _vm.resetForm($event)}}},[_c('b-form-group',{attrs:{"label":"Start Date / Time","label-for":"startDT"}},[_c('validation-provider',{attrs:{"name":"startDT","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"startDT","config":{ enableTime: true,dateFormat: 'Y-m-d H:i',altInput: true,altFormat: 'F j, Y H:i',maxDate: 'today'}},on:{"on-close":_vm.onCloseStartDateTime},model:{value:(_vm.startDateTime),callback:function ($$v) {_vm.startDateTime=$$v},expression:"startDateTime"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"End Date / Time","label-for":"endDT"}},[_c('validation-provider',{attrs:{"name":"endDT","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"endDT","config":{ enableTime: true,dateFormat: 'Y-m-d H:i',altInput: true,altFormat: 'F j, Y H:i',maxDate: 'today'}},on:{"on-close":_vm.onCloseEndDateTime},model:{value:(_vm.endDateTime),callback:function ($$v) {_vm.endDateTime=$$v},expression:"endDateTime"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Time of Day","label-for":"time_of_day"}},[_c('validation-provider',{attrs:{"name":"time_of_day","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"time_of_day","options":_vm.timeOfDayOptions,"clearable":false,"reduce":function (val) { return val.value; }},model:{value:(_vm.timeOfDay),callback:function ($$v) {_vm.timeOfDay=$$v},expression:"timeOfDay"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Speed (Frames Per Second)","label-for":"speed_fps"}},[_c('validation-provider',{attrs:{"name":"speed_fps","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"speed_fps","options":_vm.FPSOptions,"clearable":false,"reduce":function (val) { return val.value; }},model:{value:(_vm.FPSSpeed),callback:function ($$v) {_vm.FPSSpeed=$$v},expression:"FPSSpeed"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"d-flex mt-2"},[(_vm.$can('create', 'users'))?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"variant":"primary","type":"submit"}},[_vm._v(" Add ")]):_vm._e(),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"button","variant":"outline-secondary"},on:{"click":hide}},[_vm._v(" Cancel ")])],1)],1)]}}],null,true)})]}}],null,false,2318947509)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }