<template>
  <b-sidebar
    v-if="$can('create', 'cameras') || $can('create', 'camerasmanagement')"
    id="add-new-timelapse-sidebar"
    :visible="isAddNewTimelapseSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-timelapse-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add New Timelapse
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="observer"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(validateForm)"
          @reset.prevent="resetForm"
        >

          <!-- startDT -->
          <b-form-group
            label="Start Date / Time"
            label-for="startDT"
          >
            <validation-provider
              #default="{ errors }"
              name="startDT"
              rules="required"
            >
              <flat-pickr
                id="startDT"
                v-model="startDateTime"
                class="form-control"
                :config="{ enableTime: true,dateFormat: 'Y-m-d H:i',altInput: true,altFormat: 'F j, Y H:i',maxDate: 'today'}"
                @on-close="onCloseStartDateTime"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <!-- endDT -->
          <b-form-group
            label="End Date / Time"
            label-for="endDT"
          >
            <validation-provider
              #default="{ errors }"
              name="endDT"
              rules="required"
            >
              <flat-pickr
                id="endDT"
                v-model="endDateTime"
                class="form-control"
                :config="{ enableTime: true,dateFormat: 'Y-m-d H:i',altInput: true,altFormat: 'F j, Y H:i',maxDate: 'today'}"
                @on-close="onCloseEndDateTime"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <!-- Field: time_of_day -->
          <b-form-group
            label="Time of Day"
            label-for="time_of_day"
          >
            <validation-provider
              #default="{ errors }"
              name="time_of_day"
              rules="required"
            >
              <v-select
                id="time_of_day"
                v-model="timeOfDay"
                :options="timeOfDayOptions"
                :clearable="false"
                :reduce="val => val.value"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <!-- Field: speed_fps -->
          <b-form-group
            label="Speed (Frames Per Second)"
            label-for="speed_fps"
          >
            <validation-provider
              #default="{ errors }"
              name="speed_fps"
              rules="required"
            >
              <v-select
                id="speed_fps"
                v-model="FPSSpeed"
                :options="FPSOptions"
                :clearable="false"
                :reduce="val => val.value"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-if="$can('create', 'users')"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BButton,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ability from '../../../../libs/acl/ability'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BButton,
    flatPickr,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewTimelapseSidebarActive',
    event: 'update:is-add-new-timelapse-sidebar-active',
  },
  props: {
    isAddNewTimelapseSidebarActive: {
      type: Boolean,
      required: true,
    },
    cameraid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      required,
      startDateTime: '',
      endDateTime: '',
      timeOfDay: 'all',
      FPSSpeed: '12',
    }
  },
  created() {
  },
  methods: {
    onCloseStartDateTime() {
      console.log('onCloseStartDateTime')
      console.log(this.dateNtim)
    },
    onCloseEndDateTime() {
      console.log('onCloseEndDateTime')
      console.log(this.dateNtim)
    },
    validateForm() {
      // First check to ensure that the end time is not before the start time
      if (ability.can('create', 'cameras') || ability.can('create', 'camerasmanagement')) {
        const d1 = new Date(this.startDateTime)
        const d2 = new Date(this.endDateTime)
        if (d1 < d2) {
          // Build the query vars
          const qvars = {}
          qvars.camera_id = this.cameraid
          qvars.start_dt = `${this.startDateTime}:00`
          qvars.end_dt = `${this.endDateTime}:00`
          qvars.time_of_day = this.timeOfDay
          qvars.speed = this.FPSSpeed

          this.$refs.observer.validate().then(success => {
            if (success) {
              store.dispatch('app-cameraconsole/addTimelapse', qvars)
                .then(() => {
                  this.$emit('update:is-add-new-timelapse-sidebar-active', false)
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'New Timelapse is being created.',
                      icon: 'CheckIcon',
                      variant: 'success',
                      text: 'You will be notified when the timelapse is ready.',
                    },
                  })
                })
                .catch(error => {
                  console.log(error)
                  let theErrorStr = 'The following error(s) where received:\n'
                  if (typeof error.response.data.errors !== 'undefined') {
                    error.response.data.errors.forEach(item => {
                      theErrorStr += `${item.message}\n`
                    })
                  }
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Error adding Timelapse.',
                      icon: 'AlertTriangleIcon',
                      variant: 'danger',
                      text: theErrorStr,
                    },
                  })
                })
            }
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'ERROR: End Date / Time must come after Start Date / Time.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      }
    },
    resetForm() {
      this.startDateTime = ''
      this.endDateTime = ''
      this.timeOfDay = 'all'
      this.FPSSpeed = '12'
      this.$refs.observer.reset()
    },
  },
  setup() {
    const dateNtim = '2021-08-18T21:42:34'

    const timeOfDayOptions = store.state.appSettings.camera_timelapse_timeOfDay_options

    const FPSOptions = store.state.appSettings.camera_timelapse_FPS_options

    return {
      dateNtim,
      timeOfDayOptions,
      FPSOptions,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-timelapse-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
