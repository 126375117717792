<template>

  <div
    v-if="$can('read', 'cameras') || $can('read', 'camerasmanagement')"
    class="mb-2"
  >
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <!-- Filters -->
      <!-- <logs-list-filters
        :role-filter.sync="roleFilter"
        :status-filter.sync="statusFilter"
        :role-options="roleOptions"
        :status-options="statusOptions"
      /> -->
      <!-- Table Top -->
      <div class="m-2">
        <!-- <b-row>
          <b-col
            cols="12"
            md="9"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-button
              class="mr-2"
              variant="primary"
              :disabled="this.$store.state.logs.fetchingList"
              @click="handleRefreshList"
            >
              <feather-icon icon="RefreshCcwIcon" /><span class="text-nowrap ml-1">Refresh List</span>
            </b-button>
          </b-col>
          <b-col
            v-if="cameraData.is_active"
            cols="12"
            md="3"
          >
            <div
              v-if="$can('create', 'camerasmanagement') || $can('create', 'cameras')"
              class="d-flex align-items-center justify-content-end mb-1"
            >
              <b-form-datepicker
                v-model="newZipDate"
                :style="{ minWidth: '14rem' }"
                :max="maxDate"
                :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
                locale="en"
              />
            </div>
            <div
              v-if="$can('create', 'camerasmanagement') || $can('create', 'cameras')"
              class="d-flex align-items-center justify-content-end"
            >
              <b-button
                :style="{ minWidth: '14rem' }"
                variant="primary"
                @click="createNewDownload"
              >
                <feather-icon icon="DownloadIcon" /><span class="text-nowrap ml-1">Create Download</span>
              </b-button>
            </div>
          </b-col>
        </b-row> -->
        <!-- Per Page -->
        <b-row>
          <b-col
            class="col-12 col-xl-6 d-flex align-items-center justify-content-start mb-1"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
            <b-button
              class="ml-2"
              variant="primary"
              :disabled="this.$store.state.logs.fetchingList"
              @click="handleRefreshList"
            >
              <feather-icon icon="RefreshCcwIcon" />
            </b-button>
          </b-col>
          <b-col
            class="col-12 col-xl-6 d-flex align-items-left justify-content-start align-items-xl-right justify-content-xl-end mt-1 mb-1"
          >
            <div
              v-if="$can('create', 'camerasmanagement') || $can('create', 'cameras')"
              class="d-flex align-items-center justify-content-end mb-1"
            >
              <b-form-datepicker
                v-model="newZipDate"
                :style="{ minWidth: '15rem' }"
                :max="maxDate"
                :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
                locale="en"
              />
              <b-button
                class="ml-1"
                variant="primary"
                @click="createNewDownload"
              >
                <span class="text-nowrap">Create Download</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <!-- Items Table -->
      <b-table
        ref="refListTable"
        class="position-relative"
        :items="fetchItems"
        responsive
        :fields="tableColumns"
        primary-key="log_id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text=""
        :sort-desc.sync="isSortDirDesc"
        :busy.sync="isBusy"
      >

        <!-- Column: Start Date -->
        <template #cell(start_date)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.start_date.replace('T', ' ') }}</span>
          </div>
        </template>

        <!-- Column: End Date -->
        <template #cell(end_date)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.end_date.replace('T', ' ') }}</span>
          </div>
        </template>

        <!-- Column: download -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <b-button
              class="mr-2"
              variant="primary"
              @click="handleListItemClick(data.item)"
            >
              <feather-icon icon="DownloadIcon" /><span class="text-nowrap ml-1">Download</span>
            </b-button>
            <!-- <span class="align-text-top text-capitalize">{{ data.item.time_camera.replace('T', ' ') }}</span> -->
          </div>
        </template>
      </b-table>

      <!-- Pagination -->
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import { Storage } from 'aws-amplify'
import {
  BCard, BRow, BCol, BButton, BTable, BPagination, BFormDatepicker,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useImageDownloadsList from './useImageDownloadsList'

export default {
  components: {
    // LogsListFilters,

    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BPagination,
    BFormDatepicker,

    vSelect,
  },
  props: {
    cameraData: {
      type: Object,
      default: null,
    },
  },
  data() {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    // 15th in two months
    const maxDate = new Date(today)

    return {
      clients: [],
      clientsList: [],

      jsonViewerTitle: '',
      showJSONViewerModal: false,
      jsonData: '',

      newZipDate: '',
      maxDate,
    }
  },
  // computed: {
  //   isFetchingUserList() {
  //     console.log('isFetchingUserList')
  //     console.log(this.$store.getters.getFetchingUserList)
  //     return this.$store.getters.getFetchingUserList
  //   },
  // },

  setup(props) {
    console.log(props)

    const {
      fetchItems,
      tableColumns,
      perPage,
      currentPage,
      totalItems,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isBusy,
      isSortDirDesc,
      refListTable,
      refetchData,

    } = useImageDownloadsList(props.cameraData)

    return {
      fetchItems,
      tableColumns,
      perPage,
      currentPage,
      totalItems,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isBusy,
      isSortDirDesc,
      refListTable,
      refetchData,
    }
  },

  methods: {
    handleRefreshList() {
      console.log('handleRefreshList')
      this.$store.state.cameraDownloads.fetchingCameraImageDownloadsList = true
      this.refetchData()
    },
    handleListItemClick(record) {
      console.log('handleListItemClick')
      console.log(record)
      // First get the camera log file
      let accessURL = record.access_url
      if (accessURL.indexOf('public/')) {
        accessURL = accessURL.slice(accessURL.indexOf('public/') + 7)
      } else if (accessURL.indexOf('Public')) {
        accessURL = accessURL.slice(accessURL.indexOf('Public/') + 7)
      } else if (accessURL.indexOf('PUBLIC')) {
        accessURL = accessURL.slice(accessURL.indexOf('PUBLIC/') + 7)
      }
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Downloading, please await ...',
          icon: 'DownloadIcon',
          variant: 'warning',
        },
      })
      const result = Storage.get(accessURL, { download: true }).then(result1 => {
        console.log(result1)
        console.log(result)
        const theFileName = `${this.cameraData.sitename}-${this.cameraData.location_name}-${record.start_date}-${record.end_date}.zip`
        const fileUrl = window.URL.createObjectURL(result1.Body)
        const fileLink = document.createElement('a')
        fileLink.href = fileUrl
        fileLink.setAttribute('download', theFileName)
        document.body.appendChild(fileLink)
        fileLink.click()
      })
    },
    createNewDownload() {
      console.log('createNewDownload')
      console.log(this.newZipDate)
      if (this.newZipDate !== '') {
        // Call API endpoint to tell backend to create a new ZIP file of images
        const qvars1 = {
          camera_id: this.cameraData.camera_id,
          start_date: this.newZipDate,
          end_date: this.newZipDate,
        }

        store
          .dispatch('app-camera/addMediaDownloadItem', qvars1)
          .then(response1 => {
            console.log('app-camera/addMediaDownloadItem')
            console.log(response1)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Download is being created. Please wait approximately 1 minute.',
                icon: 'DownloadIcon',
                variant: 'warning',
              },
            })
            setTimeout(() => {
              this.handleRefreshList()
            }, 60000)
          })
          .catch(error => {
            console.log(error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Error sending command. ${error}`,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
