// import { Storage } from 'aws-amplify'

const ThumbsLazyImageComponent = {
  props: {
    src: {
      type: String,
      required: true,
    },
    srcPlaceholder: {
      type: String,
      default: 'data:,',
    },
    srcset: {
      type: String,
    },
    intersectionOptions: {
      type: Object,
      default: () => ({}),
    },
    usePicture: {
      type: Boolean,
      default: false,
    },
  },
  inheritAttrs: false,
  data() {
    return {
      observer: null,
      intersected: false,
      loaded: false,
      gotSignedURL: false,
      signedImageSrc: '',
    }
  },
  computed: {
    srcImage() {
      console.log('LAZYIMAGE: srcImage')
      return this.intersected && this.src ? this.src : this.srcPlaceholder
    },
    srcsetImage() {
      console.log('LAZYIMAGE: srcsetImage')
      return this.intersected && this.srcset ? this.srcset : false
    },
  },
  methods: {
    load() {
      console.log('LAZYIMAGE: load')
      if (this.$el.getAttribute('src') !== this.srcPlaceholder) {
        this.loaded = true
        this.$emit('load')
      }
    },
    error() {
      console.log('LAZYIMAGE: error')
      this.signedImageSrc = '/img/noImage.png'
      this.$emit('error', this.$el)
    },
  },
  render(h) {
    console.log('LAZYIMAGE: render')
    this.signedImageSrc = this.src
    const img = h('img', {
      attrs: {
        src: this.signedImageSrc,
        srcset: this.srcsetImage,
      },
      domProps: this.$attrs,
      class: {
        'img-fluid': true,
        'v-lazy-image': true,
        'v-lazy-image-loaded': this.loaded,
      },
      on: { load: this.load, error: this.error },
    })
    if (this.usePicture) {
      return h(
        'picture',
        { on: { load: this.load } },
        this.intersected ? [this.$slots.default, img] : [img],
      )
    // eslint-disable-next-line no-else-return
    } else {
      return img
    }
  },
  mounted() {
    console.log('LAZYIMAGE: mounted')
    if ('IntersectionObserver' in window) {
      this.observer = new IntersectionObserver(entries => {
        const image = entries[0]
        if (image.isIntersecting) {
          // if (!this.gotSignedURL) {
          //   this.gotSignedURL = true
          //   let theImageURL = this.src
          //   if (this.src.indexOf('public/')) {
          //     theImageURL = this.src.slice(this.src.indexOf('public/') + 7)
          //   } else if (this.src.indexOf('Public')) {
          //     theImageURL = this.src.slice(this.src.indexOf('Public/') + 7)
          //   } else if (this.src.indexOf('PUBLIC')) {
          //     theImageURL = this.src.slice(this.src.indexOf('PUBLIC/') + 7)
          //   }
          //   theImageURL = theImageURL.replace('photos', 'thumbs')
          //   theImageURL = theImageURL.replace('.mp4', '.jpg')
          //   Storage.get(theImageURL, { download: false }).then(result1 => {
          //     console.log(result1)
          //     this.signedImageSrc = result1
          //     this.intersected = true
          //     this.observer.disconnect()
          //     this.$emit('intersect')
          //   })
          // } else {
          //   this.intersected = true
          //   this.observer.disconnect()
          //   this.$emit('intersect')
          // }
          this.signedImageSrc = this.src
          this.intersected = true
          this.observer.disconnect()
          this.$emit('intersect')
        }
      }, this.intersectionOptions)
      this.observer.observe(this.$el)
    }
  },
  destroyed() {
    console.log('LAZYIMAGE: destroyed')
    if ('IntersectionObserver' in window) {
      this.observer.disconnect()
    }
  },
}

export default ThumbsLazyImageComponent
