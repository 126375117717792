<template>
  <b-tabs
    v-if="cameraData"
    v-model="tabIndex"
    vertical
    content-class="col-12 col-xl-10 col-lg-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-xl-2 col-lg-3 col-12"
    nav-class="nav-left"
  >
    <b-overlay
      :show="showLoadingOverlay"
      rounded="sm"
      variant="transparent"
      blur="5px"
    >
      <!-- Content Tab -->
      <b-tab v-if="($can('read', 'cameras') || $can('read', 'camerasmanagement'))">
        <!-- title -->
        <template #title>
          <feather-icon
            icon="ImageIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Images / Videos</span>
        </template>

        <div v-if="cameraMedia">
          <component :is="cameraMedia === undefined ? 'div' : 'b-card'">

            <!-- Alert: No item found -->
            <b-alert
              variant="danger"
              :show="cameraData === undefined"
            >
              <h4 class="alert-heading">
                Error fetching camera data
              </h4>
              <div class="alert-body">
                No camera found with this cameraname.
              </div>
            </b-alert>

            <camera-edit-tab-content
              ref="CameraEditTabContent"
              :camera-data="cameraData"
              :camera-media="cameraMedia"
              :users="users"
              :show-content-item="showContentItem"
              :open-calendar="openCalendar"
              @contentTabLoaded="showLoadingOverlay = false"
              @showContentTab="tabIndex = 0"
            />

          </component>
        </div>
      </b-tab>
      <!-- Calendar View -->
      <b-tab
        v-if="$can('read', 'cameras') || $can('read', 'camerasmanagement')"
        lazy
      >
        <!-- title -->
        <template #title>
          <feather-icon
            icon="CalendarIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Media Calendar</span>
        </template>
        <div v-if="cameraMedia">
          <component :is="(cameraData === undefined) ? 'div' : 'b-card'">
            <camera-edit-tab-calendar
              ref="CameraEditTabCalendar"
              :camera-data="cameraData"
              @showContentTab="tabIndex = 0"
              @showContentForDateTime="showContentForDateTime"
            />
          </component>
        </div>
      </b-tab>
      <!-- PTZ Tab -->
      <b-tab v-if="($can('update', 'cameras') || $can('update', 'camerasmanagement')) && cameraData.is_active && cameraData.is_online">
        <!-- title -->
        <template #title>
          <feather-icon
            icon="CameraIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Pan / Tilt / Zoom</span>
        </template>

        <div v-if="cameraMedia">
          <component :is="cameraMedia === undefined ? 'div' : 'b-card'">
            <camera-edit-tab-p-t-z
              ref="CameraEditTabPTZ"
              :camera-data="cameraData"
              :camera-media="cameraMedia"
            />

          </component>
        </div>
      </b-tab>
      <!-- Scheduler Tab -->
      <b-tab v-if="($can('update', 'cameras') || $can('update', 'camerasmanagement')) && cameraData.is_active && cameraData.is_online">
        <!-- title -->
        <template #title>
          <feather-icon
            icon="ClockIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Scheduler</span>
        </template>
        <div v-if="users">
          <component :is="(cameraData === undefined || users === undefined) ? 'div' : 'b-card'">
            <camera-edit-tab-scheduler
              :camera-data="cameraData"
              :users="users"
            />
          </component>
        </div>
      </b-tab>
      <!-- Notifications Tab -->
      <b-tab v-if="($can('update', 'cameras') || $can('update', 'camerasmanagement')) && cameraData.is_active">
        <!-- title -->
        <template #title>
          <feather-icon
            icon="AlertTriangleIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Notifications</span>
        </template>
        <div v-if="users">
          <component :is="(cameraData === undefined || users === undefined) ? 'div' : 'b-card'">
            <camera-edit-tab-notifications
              :camera-data="cameraData"
              :users="users"
            />
          </component>
        </div>
      </b-tab>
      <!-- Settings Tab -->
      <b-tab
        v-if="$can('update', 'cameras') || $can('update', 'camerasmanagement')"
        :active="selectedTabName === 'settings'"
      >
        <!-- title -->
        <template #title>
          <feather-icon
            icon="SettingsIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Settings</span>
        </template>

        <div v-if="users">
          <component :is="cameraData === undefined ? 'div' : 'b-card'">
            <camera-edit-tab-general
              :camera-data="cameraData"
              @goToTab="goToTab"
            />
          </component>
        </div>
      </b-tab>
      <!-- Status Tab -->
      <b-tab v-if="$can('read', 'cameras') || $can('read', 'camerasmanagement')">
        <!-- title -->
        <template #title>
          <feather-icon
            icon="ActivityIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Current Status</span>
        </template>

        <div v-if="heartbeatData">
          <component :is="cameraData === undefined ? 'div' : 'b-card'">
            <camera-edit-tab-status
              :camera-data="cameraData"
              :heartbeat-data="heartbeatData"
            />
          </component>
        </div>
      </b-tab>
      <!-- Permissions Tab -->
      <b-tab v-if="($can('update', 'cameras') || $can('update', 'camerasmanagement')) && cameraData.is_active">
        <!-- title -->
        <template #title>
          <feather-icon
            icon="LockIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Permissions</span>
        </template>
        <div v-if="users">
          <component :is="(cameraData === undefined || users === undefined) ? 'div' : 'b-card'">
            <camera-edit-tab-permissions
              :camera-data="cameraData"
              :users="users"
            />
          </component>
        </div>
      </b-tab>
      <!-- Timelapse Builder -->
      <b-tab v-if="$can('read', 'cameras') || $can('read', 'camerasmanagement')">
        <!-- title -->
        <template #title>
          <feather-icon
            icon="FilmIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Timelapse</span>
        </template>
        <div v-if="cameraData">
          <component :is="cameraData === undefined ? 'div' : 'b-card'">
            <camera-edit-tab-timelapse-builder
              ref="CameraEditTabTimelapseBuilder"
              :camera-data="cameraData"
            />
          </component>
        </div>
      </b-tab>
      <!-- Downloads -->
      <b-tab v-if="$can('read', 'cameras') || $can('read', 'camerasmanagement')">
        <!-- title -->
        <template #title>
          <feather-icon
            icon="DownloadIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Downloads</span>
        </template>
        <div v-if="cameraMedia">
          <component :is="cameraData === undefined ? 'div' : 'b-card'">
            <camera-edit-tab-downloads
              ref="CameraEditTabDownloads"
              :camera-data="cameraData"
            />
          </component>
        </div>
      </b-tab>
      <!-- Embedding -->
      <b-tab v-if="($can('update', 'cameras') || $can('update', 'camerasmanagement')) && cameraData.is_active">
        <!-- title -->
        <template #title>
          <feather-icon
            icon="LinkIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Embedding</span>
        </template>
        <div v-if="users">
          <component :is="users === undefined ? 'div' : 'b-card'">
            <camera-edit-tab-embedding
              ref="CameraEditTabEmbedding"
              :camera-data="cameraData"
            />
          </component>
        </div>
      </b-tab>
      <!-- Logs Tab -->
      <b-tab v-if="$can('read', 'camerasmanagement')">
        <!-- title -->
        <template #title>
          <feather-icon
            icon="DatabaseIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Logs</span>
        </template>
        <div v-if="users">
          <component :is="(cameraData === undefined || users === undefined) ? 'div' : 'b-card'">
            <camera-edit-tab-logs
              ref="CameraEditTabLogs"
              :camera-data="cameraData"
              :users="users"
            />
          </component>
        </div>
      </b-tab>
    </b-overlay>
  </b-tabs>
</template>

<script>
import {
  BRow, BCol, BTab, BTabs, BCard, BAlert, BLink, BOverlay,
} from 'bootstrap-vue'

import { API, Auth } from 'aws-amplify'

import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import moment from 'moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ability from '../../../../libs/acl/ability'
import cameraConsoleStoreModule from '../cameraConsoleStoreModule'
import cameraStoreModule from '../../cameras/cameraStoreModule'
import clientStoreModule from '../../clients/clientStoreModule'
import userStoreModule from '../../user/userStoreModule'
import calendarStoreModule from './calendarStoreModule'
import CameraEditTabContent from './CameraEditTabContent.vue'
import CameraEditTabPTZ from './CameraEditTabPTZ.vue'
import CameraEditTabCalendar from './CameraEditTabCalendar.vue'
import CameraEditTabTimelapseBuilder from './CameraEditTabTimelapseBuilder.vue'
import CameraEditTabDownloads from './CameraEditTabDownloads.vue'
import CameraEditTabEmbedding from './CameraEditTabEmbedding.vue'
import CameraEditTabStatus from '../../cameras/cameras-management-edit/CameraEditTabStatus.vue'
import CameraEditTabGeneral from '../../cameras/cameras-management-edit/CameraEditTabGeneral.vue'
import CameraEditTabPermissions from '../../cameras/cameras-management-edit/CameraEditTabPermissions.vue'
import CameraEditTabNotifications from '../../cameras/cameras-management-edit/CameraEditTabNotifications.vue'
import CameraEditTabScheduler from '../../cameras/cameras-management-edit/CameraEditTabScheduler.vue'
import CameraEditTabLogs from '../../cameras/cameras-management-edit/CameraEditTabLogs.vue'

export default {
  components: {
    BRow,
    BCol,
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BOverlay,

    CameraEditTabContent,
    CameraEditTabPTZ,
    CameraEditTabCalendar,
    CameraEditTabTimelapseBuilder,
    CameraEditTabDownloads,
    CameraEditTabEmbedding,
    CameraEditTabStatus,
    CameraEditTabGeneral,
    CameraEditTabPermissions,
    CameraEditTabNotifications,
    CameraEditTabScheduler,
    CameraEditTabLogs,
    ability,
  },
  props: {
    cameraid: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      // Websockets props
      conn: {},
      keepWSAlive: {},
      tabIndex: 0,
      wsKeepAlive: true,
      showContentItem: {},
      selectedTabName: 'content',
    }
  },
  setup() {
    console.log('CameraManagmentEdit Setup')
    // TODO : Saving process
    // Currently all camera data is submittede to the backend when any of the sub-forms are submitted.
    // We may need to modify this depending on EyeTrax useage requirements.

    const cameraData = ref(null)
    const users = ref(null)
    const cameraMedia = ref(null)
    const heartbeatData = ref(null)
    const gotCameraMedia = ref(false)

    const STORE_MODULE_NAME = 'app-cameraconsole'
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, cameraConsoleStoreModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const CAMERA_APP_STORE_MODULE_NAME = 'app-camera'
    // Register module
    if (!store.hasModule(CAMERA_APP_STORE_MODULE_NAME)) store.registerModule(CAMERA_APP_STORE_MODULE_NAME, cameraStoreModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CAMERA_APP_STORE_MODULE_NAME)) store.unregisterModule(CAMERA_APP_STORE_MODULE_NAME)
    })

    const CLIENT_APP_STORE_MODULE_NAME = 'app-client'
    // Register module
    if (!store.hasModule(CLIENT_APP_STORE_MODULE_NAME)) store.registerModule(CLIENT_APP_STORE_MODULE_NAME, clientStoreModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CLIENT_APP_STORE_MODULE_NAME)) store.unregisterModule(CLIENT_APP_STORE_MODULE_NAME)
    })

    const USER_APP_STORE_MODULE_NAME = 'app-user'
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const CALENDAR_APP_STORE_MODULE_NAME = 'calendar'

    // Register module
    if (!store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.registerModule(CALENDAR_APP_STORE_MODULE_NAME, calendarStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.unregisterModule(CALENDAR_APP_STORE_MODULE_NAME)
    })

    // format todays date for query
    const now = new Date()
    const todayFormated = moment(now).format('YYYYMMDD')
    console.log('todayFormated')
    console.log(todayFormated)

    // console.log(router.currentRoute.params.cameraid)
    const apiName = 'APIGateway'
    const path = `/camera/${router.currentRoute.params.cameraid}`
    // const path = '/camera/'
    const myInit = { response: true }

    Auth.currentCredentials().then(info => {
      console.log('cognitoIdentityId')
      console.log(info)
      const cognitoIdentityId = info.IdentityId
      console.log(cognitoIdentityId)
    })

    // Get the necessary Camera, Camera Media, and Users data
    const showLoadingOverlay = true
    API.get(apiName, path, myInit)
      .then(response => {
        cameraData.value = response.data
        console.log('camera data')
        console.log(cameraData.value)
        // eslint-disable-next-line no-param-reassign
        cameraData.value.status = cameraData.value.is_active
        // Determine if there is an alert to show and determine the get_date
        let currentImageDateTime = ''
        if (cameraData.value.latest_alert_show) {
          currentImageDateTime = cameraData.value.latest_alert.time_created
        } else if (typeof cameraData.value.latest_image !== 'undefined' && typeof cameraData.value.latest_image.access_url !== 'undefined' && typeof cameraData.value.latest_image.time_created !== 'undefined') {
          currentImageDateTime = cameraData.value.latest_image.time_created
        } else {
          currentImageDateTime = new Date()
        }
        const getDateFormatted = moment(currentImageDateTime).format('YYYYMMDD')
        // Get the media list for the camera
        const qvars1 = {
          camera_id: cameraData.value.camera_id,
          clientname: cameraData.value.clientname,
          get_date: getDateFormatted,
          time_zone: cameraData.value.time_zone,
          media_type: 'photos',
        }
        // Get the list of images for the camera
        store
          .dispatch('app-cameraconsole/fetchCameraMedia', qvars1)
          .then(response1 => {
            console.log('app-cameraconsole/fetchCameraMedia')
            console.log(response1)
            cameraMedia.value = response1
            gotCameraMedia.value = true
            if (ability.can('read', 'users')) {
              // Get the list of related client users
              const qvars2 = {
                client_name: cameraData.value.clientname,
              }
              store
                .dispatch('app-user/fetchClientRelatedUsers', qvars2)
                .then(response2 => {
                  console.log('app-user/fetchClientRelatedUsers')
                  console.log(response2)
                  users.value = response2.users
                  console.log('USERS')
                  console.log(users)
                })
                .catch(error => {
                  console.log('ERROR: CameraManagementEdit app-user/fetchClientRelatedUsers')
                  console.log(error)
                })
            }
          })
          .catch(error => {
            console.log('ERROR: CameraManagementEdit app-cameraconsole/fetchCameraMedia')
            console.log(error)
          })

        const qvars2 = {
          forceRefresh: false,
          q: '',
          perPage: 720,
          page: 1,
          sortBy: '',
          sortDesc: true,
          camera_id: router.currentRoute.params.cameraid,
          clientname: cameraData.value.clientname,
          media_type: 'heartbeat',
        }
        console.log('Start Fetch heartbeat logs')
        console.log(qvars2)
        store
          .dispatch('app-camera/fetchCameraLogs', qvars2)
          .then(response2 => {
            heartbeatData.value = response2.Items
            console.log('Fetch heartbeat logs')
            console.log(heartbeatData.value)
          })
          .catch(error => {
            console.log(error)
          })
      })
      .catch(error => {
        console.log('ERROR: CameraManagementEdit getting camera data')
        console.log(error)
        if (typeof error.response !== 'undefined' && error.response.status === 404) {
          cameraData.value = undefined
        }
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching Cameras list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })

    return {
      cameraData,
      users,
      cameraMedia,
      gotCameraMedia,
      showLoadingOverlay,
      heartbeatData,
    }
  },
  mounted() {
    console.log(`Mounted: ${router.currentRoute.params.cameraid}`)
    this.createWebsocketConnection()
  },
  destroyed() {
    this.conn.close()
    clearInterval(this.keepWSAlive)
    this.disconnectWebsocket()
  },
  methods: {
    // Websockets Methods
    createWebsocketConnection() {
      console.log('WEBSOCKET create connection')
      // Websocket Connection
      const wsURL = process.env.VUE_APP_AMPLIFY_WEBSOCKETURL
      // this.conn = new WebSocket('wss://ckpske6wr9.execute-api.ca-central-1.amazonaws.com/dev')
      // this.conn = new WebSocket('wss://bt7dyiyj72.execute-api.ca-central-1.amazonaws.com/production')
      this.conn = new WebSocket(wsURL)
      // this.conn = new WebSocket('wss://hl3bmlv1he.execute-api.ca-central-1.amazonaws.com/production')
      console.log(this.conn)
      const connection = this.conn
      connection.onopen = e => {
        console.log('WEBSOCKET onopen')
        console.log(router.currentRoute.params.cameraid)
        console.dir(e)
        this.sendWebsocketMessage('camera', router.currentRoute.params.cameraid)
        // this.keepWebsocketAlive()
      }
      connection.onerror = error => {
        console.error('WEBSOCKET onerror')
        console.dir(error)
      }
      connection.onmessage = e => {
        console.log('WEBSOCKET onmessage')
        console.log(e)
        try {
          if (e.data === 'ok') {
            console.log('Got OK from Web Socket')
          } else {
            const theMessage = JSON.parse(e.data)
            const theResponse = theMessage.response
            const theData = theMessage.data
            console.log(theData)
            if (typeof theResponse !== 'undefined' && theResponse === 'camera' && theData.camera_id === router.currentRoute.params.cameraid) {
              if (theData.event === 'TAKE_PICTURE') {
                console.log('WEBSOCKET - TAKE_PICTURE')
                this.$refs.CameraEditTabContent.onPictureTaken(theData.image_dt)
              } else if (theData.event === 'RETRIEVE_VIDEO') {
                console.log('WEBSOCKET - RETRIEVE_VIDEO')
                console.log(theData)
                this.$refs.CameraEditTabContent.onVideoDownloaded(theData.access_url)
              } else if (theData.event === 'PTZ_CONTROL') {
                console.log('WEBSOCKET - PTZ_CONTROL')
                this.$refs.CameraEditTabPTZ.onPTZComplete()
              } else if (theData.event === 'TIMELAPSE') {
                console.log('WEBSOCKET - TIMELAPSE')
                this.$refs.CameraEditTabTimelapseBuilder.onTimelapseComplete()
              } else if (theData.event === 'FETCH_LOG') {
                console.log('WEBSOCKET - FETCH_LOG')
                this.$refs.CameraEditTabLogs.onLogDownloaded()
              } else if (theData.event === 'ZIP') {
                console.log('WEBSOCKET - ZIP')
                this.$refs.CameraEditTabDownloads.onZIPCreated()
              }
            }
          }
        } catch (error) {
          console.log('Error interpreting message')
          console.log(error)
        }
      }
      connection.onclose = () => {
        console.log('WEBSOCKET onclose ************')
        if (this.wsKeepAlive) {
          this.createWebsocketConnection()
        }
      }
    },
    keepWebsocketAlive() {
      console.log('keepWebsocketAlive')
      clearInterval(this.keepWSAlive)
      this.keepWSAlive = setInterval(() => {
        this.sendWebsocketMessage('keepAlive', router.currentRoute.params.cameraid)
      }, 120000)
    },
    sendWebsocketMessage(theAction, theMsg) {
      console.log('sendWebsocketMessage')
      console.log(this.conn)
      if (this.conn.readyState === 3) {
        console.log('Reconnecting Websocket')
        this.createWebsocketConnection()
      } else {
        this.conn.send(JSON.stringify({
          action: theAction,
          data: theMsg,
        }))
      }
    },
    disconnectWebsocket() {
      console.log('disconnectWebsocket')
      console.log(this.conn)
      this.wsKeepAlive = false
      this.conn.close()
    },
    showContentForDateTime(item) {
      console.log('showContentForDateTime')
      console.log(item)
      this.showContentItem = item
      this.tabIndex = 0
    },
    goToTab(tabName) {
      console.log(`goToTab: ${tabName}`)
      this.selectedTabName = tabName
    },
    openCalendar() {
      console.log('CameraManagementEdit Open Calendar')
      this.tabIndex = 1
    },
  },
}
</script>

<style>

</style>
