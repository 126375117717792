<template>
  <b-sidebar
    v-if="$can('read', 'cameras') || $can('read', 'camerasmanagement')"
    id="add-new-user-sidebar"
    :visible="isFilmstripSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    header-class="d-block border-bottom-primary"
    body-class="mt-1 mb-1"
    right
    @change="(val) => $emit('update:is-filmstrip-sidebar-active', val)"
    @shown="handleShowFilmstrip"
  >
    <template #header="{ hide }">
      <!-- Header -->
      <b-row class="mt-1">
        <b-col>
          <h5 class="mb-0">
            <h4>Images & Videos</h4>
          </h5>
        </b-col>
        <b-col class="text-right">
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </b-col>
      </b-row>
      <!-- Date / Time Picker -->
      <b-row class="d-none d-md-block mb-2 pr-1">
        <b-col>
          <div class="input-group">
            <flat-pickr
              v-model="currentDateTime"
              placeholder="Select Start Date / Time"
              class="form-control"
              :config="{ enableTime: true, dateFormat: 'Y-m-d H:i', altInput: true, altFormat: 'F j, Y H:i' }"
              @on-close="onCloseImageDateTime"
              @on-open="showcheckBtn = true"
            />
            <div class="input-group-btn">
              <b-button
                v-if="showcheckBtn"
                class="pl-1 pr-1"
                variant="primary"
              >
                <feather-icon icon="CheckIcon" />
              </b-button>
            </div>
          </div>
        </b-col>
      </b-row>
    </template>
    <template #default="">
      <!-- Images and Videos List -->
      <div
        v-for="theDateItem in finalCameraMediaArr"
        :key="'theDate-' + theDateItem.theDate.replace('T','').replace(' ','').replace('-','').replace(':','')"
        class="ml-1 mr-1"
      >
        <!-- Date Sub-Header -->
        <b-row class="mt-2 mb-1 border-bottom-primary">
          <b-col
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <h5 class="mb-0">
              <h4>{{ theDateItem.theDateDisplay }}</h4>
            </h5>
          </b-col>
        </b-row>

        <!-- Cameras List -->
        <section
          class="grid-view"
        >
          <b-card
            v-for="mediaItem in theDateItem.mediaItems"
            :id="mediaItem.time_camera.replace('T','').replace(' ','').replaceAll('-','').replaceAll(':','')"
            :ref="mediaItem.time_camera.replace('T','').replace(' ','').replaceAll('-','').replaceAll(':','')"
            :key="'filmstrip-' + mediaItem.time_camera.replace('T','').replace(' ','').replaceAll('-','').replaceAll(':','')"
            no-body
          >
            <div
              class="item-img text-center"
              @click="$emit('itemclicked', mediaItem)"
            >
              <!-- <b-img-lazy
                :alt="`${mediaItem.clientname}-${mediaItem.camera_id}-${mediaItem.sitename}`"
                fluid
                class="card-img-top"
                :src="mediaItem.thumb_url"
                throttle="10000"
              /> -->
              <thumbs-lazy-image-component
                class="card-img-top"
                :src="mediaItem.access_url"
                src-placeholder="@/assets/images/noImage.png"
              />
            </div>

            <b-card-footer class="pt-1 pb-1 border-dark">
              <b-row>
                <b-col cols="8">
                  <small class="text-muted">Image Time: </small><small>{{ formatDateTimeAMPM(mediaItem.time_camera.replace('T', ' ')) }}</small>
                </b-col>
                <b-col
                  v-if="mediaItem.video_file !== undefined"
                  cols="4"
                >
                  <b-badge
                    pill
                    variant="light-danger"
                    class="text-capitalize"
                  >
                    Video
                  </b-badge>
                </b-col>
                <b-col
                  v-else
                  cols="4"
                >
                  <b-badge
                    pill
                    variant="light-success"
                    class="text-capitalize"
                  >
                    Image
                  </b-badge>
                </b-col>
              </b-row>
            </b-card-footer>
          </b-card>
        </section>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BRow, BCol, BCard, BCardFooter, BBadge,
} from 'bootstrap-vue'
import { required } from '@validations'
// import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'
import { watch } from '@vue/composition-api'
import flatPickr from 'vue-flatpickr-component'
import ThumbsLazyImageComponent from './thumbsLazyImageComponent'

export default {
  components: {
    BSidebar,
    BRow,
    BCol,
    BCard,
    BCardFooter,
    flatPickr,
    BBadge,
    ThumbsLazyImageComponent,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isFilmstripSidebarActive',
    event: 'update:is-filmstrip-sidebar-active',
  },
  props: {
    isFilmstripSidebarActive: {
      type: Boolean,
      required: true,
    },
    cameraIDProp: {
      type: String,
      required: false,
      default: '',
    },
    cameraData: {
      type: Object,
      default: null,
    },
    cameraMedia: {
      type: Object,
      default: null,
    },
    currentImageDateTime: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      required,
      timevalue: '',
      timevalue1: '',
      finalCameraMediaArr: [],
      showcheckBtn: false,
    }
  },
  setup() {
    const currentDateTime = ''

    const formatDateTimeAMPM = date => {
      const theDate = new Date(date.replace(/-/g, '/'))
      const year = theDate.getFullYear()
      let month = theDate.getMonth() + 1
      if (month < 10) month = `0${month}`
      let day = theDate.getDate()
      if (day < 10) day = `0${day}`
      let hours = theDate.getHours()
      let minutes = theDate.getMinutes()
      const ampm = hours >= 12 ? 'PM' : 'AM'
      hours %= 12
      hours = hours || 12
      minutes = minutes.toString().padStart(2, '0')
      const strTime = `${year}-${month}-${day} ${hours}:${minutes} ${ampm}`
      return strTime
    }

    return {
      currentDateTime,
      formatDateTimeAMPM,
    }
  },
  watch: {
    currentImageDateTime() {
      console.log('FILMSTRIP watch: currentImageDateTime')
      if (this.isFilmstripSidebarActive) {
        this.currentDateTime = this.currentImageDateTime
        this.findMediaItemByDateTime()
      }
    },
  },
  created() {
    watch(this.cameraMedia, () => {
      console.log('watch: cameraMedia')
      this.processCameraMedia()
    }, {
      deep: true,
    })

    this.processCameraMedia()

    window.addEventListener('keyup', this.doKeyboardCommands)
  },
  mounted() {
    // this.handleShowFilmstrip()
  },
  destroyed() {
    window.removeEventListener('keyup', this.doKeyboardCommands)
  },
  methods: {
    doKeyboardCommands(e) {
      const cmd = String.fromCharCode(e.keyCode).toLowerCase()
      // Next Previous
      if (cmd === '&') {
        // scroll up
      } else if (cmd === '(') {
        // Scroll down
      }
    },
    handleShowFilmstrip() {
      console.log('FILMSTRIP: handleShowFilmstrip')
      // console.log(this.currentImageDateTime)
      // console.log(this.currentImageDateTime.replace('T', '').replace(' ', '').replaceAll('-', '').replaceAll(':', ''))
      // Scoll to current Image
      // console.log(this.$refs)
      const el = this.$refs[this.currentImageDateTime.replace('T', '').replace(' ', '').replaceAll('-', '').replaceAll(':', '')][0]
      // console.log(el)
      el.scrollIntoView(true)
    },
    processCameraMedia() {
      console.log('FILMSTRIP: processCameraMedia')
      // Go through the camera media and organize into days
      this.finalCameraMediaArr = []
      // first loop through and get the list of unique dates
      this.cameraMedia.Items.forEach(item => {
        const theDate = new Date(item.time_camera)
        const getDate = moment(theDate).format('YYYYMMDD')
        const obj = this.finalCameraMediaArr.find(o => o.theDate === getDate)
        if (typeof obj === 'undefined') {
          const theDateDisplay1 = moment(theDate).format('MMMM Do, YYYY')
          const tmp = { theDate: getDate, theDateDisplay: theDateDisplay1 }
          this.finalCameraMediaArr.push(tmp)
        }
      })
      console.log('finalCameraMediaArr')
      console.log(this.finalCameraMediaArr)
      // Then loop through and add in the media items
      this.finalCameraMediaArr.forEach((dateItems, dateIndex) => {
        const tmpArr = []
        this.cameraMedia.Items.forEach(item => {
          const theDate = new Date(item.time_camera)
          const getDate = moment(theDate).format('YYYYMMDD')
          if (dateItems.theDate === getDate) {
            tmpArr.push(item)
          }
        })
        this.finalCameraMediaArr[dateIndex].mediaItems = tmpArr
      })
      // console.log('finalCameraMediaArr')
      // console.log(this.finalCameraMediaArr)
    },
    findMediaItemByDateTime() {
      console.log('FILMSTRIP: findMediaItemByDateTime')
      let shouldSkip = false
      let shouldSkip2 = false
      this.finalCameraMediaArr.forEach(dateItems => {
        if (shouldSkip) {
          return
        }
        const theDate = new Date(this.currentDateTime)
        const getDate = moment(theDate).format('YYYYMMDD')
        // Find the date first
        if (dateItems.theDate === getDate) {
          shouldSkip = true
          // then look for the item to set
          dateItems.mediaItems.forEach(item => {
            if (shouldSkip2) {
              return
            }
            let itemDateTime = new Date(item.time_camera)
            let currDateTime = new Date(this.currentDateTime)
            itemDateTime = itemDateTime.getTime()
            currDateTime = currDateTime.getTime()
            if (itemDateTime <= currDateTime) {
              // found the first image that is less than or equal to the date/time selected
              shouldSkip2 = true
              this.$emit('setcurrentimage', item)
              this.handleShowFilmstrip()
            }
          })
        }
      })
    },
    onCloseImageDateTime() {
      console.log('FILMSTRIP: onCloseImageDateTime')
      console.log(this.currentDateTime)
      this.showcheckBtn = false
      this.$emit('setcurrentdatetime', this.currentDateTime)

      // // First check to see if the date/time selected is within the current camera media dataset
      // if (this.cameraMedia.Items[0].time_camera.replace('T', ' ') >= this.currentDateTime && this.cameraMedia.Items[this.cameraMedia.Items.length - 1].time_camera.replace('T', ' ') <= this.currentDateTime) {
      //   // the date/time selected has already been loaded in the current camera media dataset
      //   console.log('in the current camera media dataset')
      //   this.findMediaItemByDateTime()
      // } else {
      //   // Get an updated list of camera media
      //   // Use this Process if data is per day
      //   console.log('Get an updated list of camera media')
      //   const getDate = moment(this.currentDateTime).format('YYYYMMDD')
      //   console.log(getDate)
      //   const qvars = {
      //     mediaType: 'photos',
      //     clientname: this.cameraData.clientname,
      //     getDate,
      //     timeZone: this.cameraData.time_zone,
      //     startTime: null,
      //     endTime: null,
      //     startKey: null,
      //     forceRefresh: true,
      //   }
      //   this.$emit('getcameramedia', qvars)
      // }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import "~@core/scss/base/pages/app-ecommerce.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
