import store from '@/store'
import { ref } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import moment from 'moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ability from '../../../../libs/acl/ability'
// import moment from 'moment'

export const useTimelapseFiltersSortingAndPagination = () => {
  const filters = ref({
    q: '',
    startTime: '',
    endTime: '',
    page: 1,
    perPage: 9,
  })

  return {
    filters,
  }
}

export const useCameraMediaData = () => {
  const toast = useToast()
  const fetchTimelapseItemsFromMedia = qvars => {
    console.log('fetchTimelapseItemsFromMedia')
    console.log(qvars)
    return new Promise((resolve, reject) => {
      if (ability.can('read', 'cameras') || ability.can('read', 'camerasmanagement')) {
        store
          .dispatch('app-cameraconsole/fetchCameraMediaTimelapse', qvars)
          .then(response => {
            console.log('app-cameraconsole/fetchCameraMedia')
            console.log(response)
            const theResponse = response
            theResponse.Items.forEach((item, index) => {
              const startDT = new Date(item.start_dt)
              const endDT = new Date(item.end_dt)
              theResponse.Items[index].startDT = moment(startDT).format('YYYY-MM-DD HH:mm')
              theResponse.Items[index].endDT = moment(endDT).format('YYYY-MM-DD HH:mm')
              theResponse.Items[index].theIndex = index
            })
            resolve(theResponse)
          })
          .catch(error => {
            console.log(error)
            toast({
              component: ToastificationContent,
              props: {
                title: 'Error fetching Timelapse list',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      } else {
        reject()
      }
    })
  }

  return {
    fetchTimelapseItemsFromMedia,
  }
}
