<template>
  <div>
    <div>
      <b-row class="mb-1">
        <b-col class="col-12 col-md-8">
          <h4 class="mb-1">
            <feather-icon
              icon="CameraIcon"
              size="22"
              class="mr-50"
            />
            Pan / Tilt / Zoom -- {{ cameraData.sitename }}, {{ cameraData.location_name }}
          </h4>
          <small>Camera: {{ cameraData.unit_number }}</small><br>
        </b-col>
        <b-col
          class="col-12 col-md-4 text-right"
        >
          <b-button
            v-if="$can('update', 'cameras') || $can('update', 'camerasmanagement')"
            id="btnTakePic"
            variant="primary"
            class="mr-1"
            type="button"
            @click="takePicture"
          >
            <feather-icon icon="CameraIcon" />
          </b-button>
          <b-tooltip
            target="btnTakePic"
            title="Take Picture"
            placement="bottom"
          />
        </b-col>
      </b-row>
      <b-row v-if="$can('read', 'cameras') || $can('read', 'camerasmanagement')">
        <!-- Media -->
        <b-col class="col-12 col-lg-9">
          <b-media
            class="mb-1"
            vertical-align="center"
          >
            <b-overlay
              :show="showLoadingOverlay"
              rounded="sm"
              variant="transparent"
              blur="5px"
            >
              <b-img
                v-if="theImage !== ''"
                class="rounded img-fluid w-100"
                fluid
                :src="theImage"
              />
              <b-img
                v-else
                class="rounded img-fluid w-100"
                fluid
                src="@/assets/images/noImageLg.png"
              />
            </b-overlay>
          </b-media>
        </b-col>
        <b-col class="col-12 col-lg-3">
          <b-row
            align-v="center"
            class="mt-1"
          >
            <b-col class="text-center mb-1">
              <b-row align-h="center">
                <b-button
                  id="btnUp"
                  variant="primary"
                  type="button"
                  :disabled="showLoadingOverlay"
                  @click="moveCamera('up')"
                >
                  <feather-icon icon="ArrowUpIcon" />
                </b-button>
              </b-row>
              <b-row
                align-h="center"
                class="mt-1 mb-1"
              >
                <b-button
                  id="btnLeft"
                  variant="primary"
                  class="pl-2 pr-2 mr-1"
                  type="button"
                  :disabled="showLoadingOverlay"
                  @click="moveCamera('left')"
                >
                  <feather-icon icon="ArrowLeftIcon" />
                </b-button>
                <b-button
                  id="btnRight"
                  variant="primary"
                  class="pl-2 pr-2"
                  type="button"
                  :disabled="showLoadingOverlay"
                  @click="moveCamera('right')"
                >
                  <feather-icon icon="ArrowRightIcon" />
                </b-button>
              </b-row>
              <b-row align-h="center">
                <b-button
                  id="btnDown"
                  variant="primary"
                  type="button"
                  :disabled="showLoadingOverlay"
                  @click="moveCamera('down')"
                >
                  <feather-icon icon="ArrowDownIcon" />
                </b-button>
              </b-row>
              <b-row
                v-if="(cameraData.device_path === '192.168.1.108' || cameraData.device_path === '192.168.1.110') && cameraData.camera_type === 'Predator'"
                align-h="center"
                class="mt-3 mb-1"
              >
                <b-button
                  id="btnZIn"
                  variant="primary"
                  class="mr-1"
                  type="button"
                  :disabled="showLoadingOverlay"
                  @click="moveCamera('zoom_in')"
                >
                  <feather-icon icon="ZoomInIcon" />
                </b-button>
                <b-tooltip
                  target="btnZIn"
                  title="Zoom In"
                  placement="bottom"
                />
                <b-button
                  id="btnZout"
                  variant="primary"
                  type="button"
                  :disabled="showLoadingOverlay"
                  @click="moveCamera('zoom_out')"
                >
                  <feather-icon icon="ZoomOutIcon" />
                </b-button>
                <b-tooltip
                  target="btnZout"
                  title="Zoom Out"
                  placement="bottom"
                />
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { Storage } from 'aws-amplify'
import {
  BMedia, BRow, BCol, BImg, BButton, BTooltip, BOverlay,
} from 'bootstrap-vue'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ability from '../../../../libs/acl/ability'

export default {
  components: {
    BMedia,
    BRow,
    BCol,
    BImg,
    BButton,
    BTooltip,
    BOverlay,
  },
  props: {
    cameraData: {
      type: Object,
      default: null,
    },
    cameraMedia: {
      type: Object,
      default: null,
    },
  },
  setup() {
  },
  data() {
    return {
      status: '',
      options: {},
      signalOptions: {},
      theImage: '',
      timevalue: '',
      timevalue1: '',
      currentImage: '',
      liveImageItem: {},
      showLoadingOverlay: false,
    }
  },
  created() {
    // get the latest Live Pic and compare it to the latest image - display the most recent
    let useLatestImage = false
    this.getLatestLivePic().then(() => {
      // Compare images
      if (typeof this.cameraData.latest_image !== 'undefined' && typeof this.cameraData.latest_image.access_url !== 'undefined') {
        if (typeof this.liveImageItem.time_camera !== 'undefined') {
          let latestDateTime = new Date(this.cameraData.latest_image.time_created)
          let liveDateTime = new Date(this.liveImageItem.time_camera)
          latestDateTime = latestDateTime.getTime()
          liveDateTime = liveDateTime.getTime()
          if (latestDateTime > liveDateTime) {
            useLatestImage = true
          }
        } else {
          useLatestImage = true
        }
      }
      if (useLatestImage) {
        console.log('PTZ Use Lastest Image')
        this.currentImage = this.cameraData.latest_image.access_url
      } else {
        console.log('PTZ Use Live Image')
        this.currentImage = this.liveImageItem.access_url
      }
      console.log(this.currentImage)
      if (this.currentImage !== undefined) {
        this.getImage(this.currentImage)
      }
    })
  },
  methods: {
    takePicture() {
      // Call API to tell camera to take picture
      if (ability.can('create', 'cameras') || ability.can('create', 'camerasmanagement')) {
        const qvars1 = {
          camera_id: this.cameraData.camera_id,
          command: 'TAKE_PICTURE',
        }

        store
          .dispatch('app-cameraconsole/commandCamera', qvars1)
          .then(response1 => {
            console.log('app-cameraconsole/commandCamera')
            console.log(response1)
            // TODO: Need to add AWS IoT listening to know when the picture is taken and refresh the main image with the new image
            // Also need to get an updated list of image media for the camera if past a certain time
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Command sent to camera. You will be notified when image is retreived.',
                icon: 'CameraIcon',
                variant: 'warning',
              },
            })
          })
          .catch(error => {
            console.log(error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Error sending command to camera. ${error}`,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
    },
    getImage(img) {
      console.log('getImage')
      if (ability.can('read', 'cameras') || ability.can('read', 'camerasmanagement')) {
        this.theImageURL = img
        if (img.indexOf('public/')) {
          this.theImageURL = img.slice(img.indexOf('public/') + 7)
        } else if (img.indexOf('Public')) {
          this.theImageURL = img.slice(img.indexOf('Public/') + 7)
        } else if (img.indexOf('PUBLIC')) {
          this.theImageURL = img.slice(img.indexOf('PUBLIC/') + 7)
        }
        Storage.get(this.theImageURL, { download: false }).then(result1 => {
          this.theImage = result1
          this.showLoadingOverlay = false
        })
      }
    },
    getLatestLivePic() {
      return new Promise(resolve => {
        console.log('getLatestLivePic')
        if (ability.can('read', 'cameras') || ability.can('read', 'camerasmanagement')) {
          // Get the latest Live Pic image
          const qvars1 = {}
          qvars1.camera_id = this.cameraData.camera_id
          qvars1.clientname = this.cameraData.clientname
          store
            .dispatch('app-cameraconsole/fetchLivePic', qvars1)
            .then(response1 => {
              console.log('app-cameraconsole/fetchLivePic')
              console.log(response1)
              // eslint-disable-next-line prefer-destructuring
              this.liveImageItem = response1
              resolve()
            })
            .catch(error => {
              console.log(error)
              this.liveImageItem = {}
              this.showLoadingOverlay = false
              resolve()
            })
        }
      })
    },
    setLatestLivePic() {
      this.getLatestLivePic().then(() => {
        this.getImage(this.liveImageItem.access_url)
      })
    },
    moveCamera(direction) {
      // Call API to tell camera to take picture
      if (ability.can('read', 'cameras') || ability.can('read', 'camerasmanagement')) {
        this.showLoadingOverlay = true
        const qvars1 = {
          camera_id: this.cameraData.camera_id,
          command: 'PTZ_CONTROL',
          action: direction,
        }

        store
          .dispatch('app-cameraconsole/commandCamera', qvars1)
          .then(response1 => {
            console.log('app-cameraconsole/commandCamera')
            console.log(response1)
            // TODO: Need to add AWS IoT listening to know when the picture is taken and refresh the main image with the new image
            // Also need to get an updated list of image media for the camera if past a certain time
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Command sent to camera. You will be notified when image is retreived.',
                icon: 'CameraIcon',
                variant: 'warning',
              },
            })
          })
          .catch(error => {
            console.log(error)
            this.showLoadingOverlay = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Error sending command to camera. ${error}`,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
    },
    onPTZComplete() {
      console.log('onPTZComplete')
      this.setLatestLivePic()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '~@core/scss/base/pages/app-ecommerce.scss';
</style>
