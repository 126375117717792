import { API } from 'aws-amplify'
import ability from '../../../../libs/acl/ability'

export default {
  namespaced: true,
  state: {
    calendarOptions: [
      {
        color: 'danger',
        label: 'Videos',
      },
      {
        color: 'info',
        label: 'Images',
      },
    ],
    selectedCalendars: ['Videos', 'Images'],
  },
  getters: {},
  mutations: {
    SET_SELECTED_EVENTS(state, val) {
      state.selectedCalendars = val
    },
  },
  actions: {
    fetchEvents(ctx, qvars) {
      return new Promise((resolve, reject) => {
        if (ability.can('read', 'cameras') || ability.can('read', 'camerasmanagement')) {
          console.log('commandCamera')
          const apiName = 'APIGateway'
          const path = `/camera/${qvars.camera_id}/calendar/${qvars.year}/${qvars.month}`
          const bodyData = { clientname: qvars.clientname }

          API.post(apiName, path, { body: bodyData })
            .then(response => {
              // console.log(response)
              const theResponse = response
              resolve(theResponse)
            })
            .catch(error => reject(error))
        } else {
          reject()
        }
      })
    },
  },
}
