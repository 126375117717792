<template>
  <div>
    <camera-edit-tab-timelapse-builder-add-new
      :v-if="cameraData && ($can('create', 'cameras') || $can('create', 'camerasmanagement'))"
      :is-add-new-timelapse-sidebar-active.sync="isAddNewTimelapseSidebarActive"
      :cameraid.sync="cameraData.camera_id"
    />

    <!-- Media -->
    <b-media
      v-if="$can('read', 'cameras') || $can('read', 'camerasmanagement')"
      class="mb-2"
    >
      <h4 class="mb-1">
        <feather-icon
          icon="CameraIcon"
          size="22"
          class="mr-50"
        />
        Timelapse Builder -- {{ cameraData.sitename }}, {{ cameraData.location_name }}<br>
      </h4>
      <small>Camera: {{ cameraData.unit_number }}</small>
      <b-badge
        v-if="!cameraData.is_active"
        pill
        variant="light-danger"
        class="text-capitalize ml-1"
      >
        Disabled
      </b-badge>
      <br>
      <small>Time Zone: {{ cameraData.time_zone }}</small><br>
    </b-media>

    <!-- Videos List -->
    <div
      v-if="$can('read', 'cameras') || $can('read', 'camerasmanagement')"
      class="ecommerce-application"
      style="height: inherit"
    >
      <!-- Table Top -->
      <!-- <b-row>
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <b-button
            class="mr-2"
            variant="primary"
            :disabled="this.$store.state.cameraconsole.fetchingTimelapseList"
            @click="reFetchViewItems"
          >
            <feather-icon icon="RefreshCcwIcon" /><span class="text-nowrap ml-1">Refresh List</span>
          </b-button>
        </b-col>
        <b-col
          v-if="cameraData.is_active"
          cols="12"
          md="6"
        >
          <div
            v-if="$can('create', 'cameras') || $can('create', 'camerasmanagement')"
            class="d-flex align-items-center justify-content-end"
          >
            <b-button
              variant="primary"
              @click="isAddNewTimelapseSidebarActive = true"
            >
              <feather-icon icon="FilmIcon" /><span class="text-nowrap ml-1">Add Timelapse</span>
            </b-button>
          </div>
        </b-col>
      </b-row> -->
      <!-- Per Page / Search -->
      <b-row
        v-if="$can('read', 'cameras') || $can('read', 'camerasmanagement')"
        class="mb-1"
      >
        <b-col
          md="6"
          class="col-push-12 col-lg-6 d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>Show</label>
          <v-select
            v-model="filters.perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
          />
          <label>entries</label>
          <b-button
            class="ml-2"
            variant="primary"
            :disabled="this.$store.state.cameraconsole.fetchingTimelapseList"
            @click="reFetchViewItems"
          >
            <feather-icon icon="RefreshCcwIcon" />
          </b-button>
          <b-button
            class="ml-2"
            variant="primary"
            @click="isAddNewTimelapseSidebarActive = true"
          >
            <feather-icon icon="PlusIcon" />
          </b-button>
        </b-col>
        <b-col
          md="3"
          class="col-push-12 col-lg-3 d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>From </label>
          <!-- Date / Time Picker -->
          <div class="input-group">
            <flat-pickr
              v-model="filters.startTime"
              class="form-control ml-1"
              :config="{ enableTime: true, dateFormat: 'Y-m-d H:i', altInput: true, altFormat: 'F j, Y H:i' }"
              @on-close="startTimeCheckBtn = false"
              @on-open="startTimeCheckBtn = true"
            />
            <div class="input-group-btn">
              <b-button
                v-if="startTimeCheckBtn"
                class="pl-1 pr-1"
                variant="primary"
              >
                <feather-icon icon="CheckIcon" />
              </b-button>
            </div>
          </div>
        </b-col>
        <b-col
          md="3"
          class="col-push-12 col-lg-3 d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>To </label>
          <!-- Date / Time Picker -->
          <div class="input-group">
            <flat-pickr
              v-model="filters.endTime"
              class="form-control ml-1"
              :config="{ enableTime: true, dateFormat: 'Y-m-d H:i', altInput: true, altFormat: 'F j, Y H:i' }"
              @on-close="endTimeCheckBtn = false"
              @on-open="endTimeCheckBtn = true"
            />
            <div class="input-group-btn">
              <b-button
                v-if="endTimeCheckBtn"
                class="pl-1 pr-1"
                variant="primary"
              >
                <feather-icon icon="CheckIcon" />
              </b-button>
            </div>
          </div>
        </b-col>
      </b-row>

      <!-- Videos List -->
      <section
        v-if="$can('read', 'cameras') || $can('read', 'camerasmanagement')"
        class="grid-view"
      >
        <b-card
          v-for="item in timelapseItems"
          :id="`${item.theIndex}_timelapse`"
          :key="`${item.theIndex}_timelapse`"
          no-body
          role="button"
          @click="playVideoFile(item)"
        >
          <div class="item-img text-center">
            <!-- <b-img
              v-if="item.thumb_url !== undefined && item.thumb_url !== ''"
              :alt="`${item.start_dt}`"
              fluid
              class="card-img-top"
              :src="item.thumb_url"
            /> -->
            <thumbs-lazy-image-component
              v-if="item.access_url !== undefined && item.access_url !== ''"
              class="card-img-top"
              :src="item.access_url"
            />
            <b-img
              v-else
              :alt="`${item.start_dt}`"
              fluid
              class="card-img-top"
              src="@/assets/images/noImageLg.png"
            />
          </div>

          <b-card-footer class="pt-1 pb-1 border-dark">
            <small class="text-muted">Start Date: </small><small>{{ formatDateTimeAMPM(item.start_dt) }}</small><br>
            <small class="text-muted">End Date: </small><small>{{ formatDateTimeAMPM(item.end_dt) }}</small><br>
            <small class="text-muted">Time of Day: </small><small>{{ item.time_of_day }}</small><br>
            <small class="text-muted">Speed: </small><small>{{ item.speed }} fps</small>
          </b-card-footer>
        </b-card>
      </section>

      <!-- Pagination -->
      <section v-if="$can('read', 'cameras') || $can('read', 'camerasmanagement')">
        <b-row>
          <b-col cols="12">
            <b-pagination
              v-model="filters.page"
              :total-rows="totalTimelapseItems"
              :per-page="filters.perPage"
              first-number
              align="center"
              last-number
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </section>
    </div>
    <!-- Video Modal -->
    <b-modal
      v-if="$can('read', 'cameras') || $can('read', 'camerasmanagement')"
      id="vmodal"
      ref="vmodal"
      ok-only
      ok-title="Close"
      centered
      size="lg"
      :title="videoTitle"
      @close="showVideoModal = false"
    >
      <vue-plyr :options="options">
        <video
          controls
          crossorigin
          playsinline
          :data-poster="videoPoster"
        >
          <source
            size="1080"
            :src="videoSrc"
            type="video/mp4"
          >
          <!-- <track
            default
            kind="captions"
            label="English captions"
            src="/path/to/english.vtt"
            srclang="en"
          > -->
        </video>
      </vue-plyr>
      <b-button
        id="button-video-download"
        variant="primary"
        class="mt-1"
        type="button"
        @click="downloadVideo"
      >
        <feather-icon icon="DownloadIcon" /> Download Video File
      </b-button>
    </b-modal>
  </div>
</template>

<script>
import {
  BMedia, BRow, BCol, BCard, BPagination, BCardFooter, BImg, BButton, BBadge,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import store from '@/store'
import { Storage } from 'aws-amplify'
import moment from 'moment'
import flatPickr from 'vue-flatpickr-component'
import { ref } from '@vue/composition-api'
import VuePlyr from 'vue-plyr'
import { useTimelapseFiltersSortingAndPagination, useCameraMediaData } from './useCameraConsoleTimelapseList'
import CameraEditTabTimelapseBuilderAddNew from './CameraEditTabTimelapseBuilderAddNew.vue'
import ThumbsLazyImageComponent from './thumbsLazyImageComponent'
import ability from '../../../../libs/acl/ability'

export default {
  components: {
    BMedia,
    BRow,
    BCol,
    vSelect,
    BCard,
    BPagination,
    BCardFooter,
    BImg,
    BButton,
    BBadge,
    CameraEditTabTimelapseBuilderAddNew,
    flatPickr,
    VuePlyr,
    ThumbsLazyImageComponent,
  },
  props: {
    cameraData: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const isAddNewTimelapseSidebarActive = ref(false)
    console.log('Timelapse Setup')
    console.log(props.cameraData)

    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    // two months prior
    const minDate = new Date(today)
    minDate.setMonth(minDate.getMonth() - 2)
    // today
    const maxDate = new Date(today)

    // ****************************************************************
    // ***** List Related
    const {
      filters,
    } = useTimelapseFiltersSortingAndPagination()

    const timelapseItems = ref(null)
    const totalTimelapseItems = ref(null)
    const { fetchTimelapseItemsFromMedia } = useCameraMediaData()

    // Wrapper Function for `fetchProducts` which can be triggered initially and upon changes of filters
    const fetchTimelapseItems = () => {
      fetchTimelapseItemsFromMedia({
        forceRefresh: store.state.cameraconsole.fetchingTimelapseList,
        camera_id: props.cameraData.camera_id,
        clientname: props.cameraData.clientname,
        get_date: moment(filters.value.getDate).format('YYYYMMDD'),
        time_zone: props.cameraData.time_zone,
        media_type: 'timelapse',
        start_time: filters.value.startTime,
        end_time: filters.value.endTime,
        page: filters.value.page,
        perPage: filters.value.perPage,
      })
        .then(response => {
          console.log(response)
          timelapseItems.value = response.Items
          totalTimelapseItems.value = response.totalItems
        })
    }

    const reFetchViewItems = () => {
      console.log('Timelapse reFetchViewItems')
      store.state.cameraconsole.fetchingTimelapseList = true
      filters.value.page = 1
      filters.value.startTime = null
      filters.value.endTime = null
      console.log(filters)
      fetchTimelapseItems()
    }

    const perPageOptions = [9, 18, 27, 36, 54, 99]

    const formatDateTimeAMPM = date => {
      const theDate = new Date(date.replace(/-/g, '/'))
      const year = theDate.getFullYear()
      let month = theDate.getMonth() + 1
      if (month < 10) month = `0${month}`
      let day = theDate.getDate()
      if (day < 10) day = `0${day}`
      let hours = theDate.getHours()
      let minutes = theDate.getMinutes()
      const ampm = hours >= 12 ? 'PM' : 'AM'
      hours %= 12
      hours = hours || 12
      minutes = minutes.toString().padStart(2, '0')
      const strTime = `${year}-${month}-${day} ${hours}:${minutes} ${ampm}`
      return strTime
    }

    return {
      isAddNewTimelapseSidebarActive,

      // useShopFiltersSortingAndPagination
      filters,

      perPageOptions,

      totalTimelapseItems,
      timelapseItems,

      minDate,
      maxDate,

      fetchTimelapseItems,
      reFetchViewItems,
      formatDateTimeAMPM,
    }
  },
  data() {
    return {
      status: '',
      options: {},
      signalOptions: {},
      theImage: '',
      timevalue: '',
      timevalue1: '',
      // Video Player related props
      showVideoModal: false,
      videoTitle: '',
      videoPoster: '',
      videoURL: '',
      videoSrc: '',
      videoDownloadRequestItem: {},
      videoToastcount: 0,
      startTimeCheckBtn: false,
      endTimeCheckBtn: false,
    }
  },
  created() {
    console.log(this.filters)
    this.$watch(() => this.filters, () => {
      this.fetchTimelapseItems()
    }, {
      deep: true,
    })
    this.$watch(() => this.timelapseListKey, () => {
      this.forceRerender()
    }, {
      deep: true,
    })
    this.fetchTimelapseItems()
  },
  methods: {
    onCloseDatePicker() {
      console.log('onCloseDatePicker')
      console.log(this.filters)
    },
    onTimelapseComplete() {
      console.log('onTimelapseComplete')
      this.reFetchViewItems()
      // create the Toast notification
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Timelapse created. Please see the Timelapse list to view.',
          icon: 'CameraIcon',
          variant: 'warning',
        },
      })
    },
    playVideoFile(videoItem) {
      console.log('playVideoFile')
      if (ability.can('read', 'cameras') || ability.can('read', 'camerasmanagement')) {
        console.log(videoItem)
        // Determine if video has already been downloaded Based on video_access_url property
        if (videoItem.video_access_url !== '') {
          console.log('play Video File')
          // Play the video in the video player
          // First set the scene properties
          this.videoTitle = `${this.cameraData.sitename} -- ${this.cameraData.location_name} -- ${videoItem.startDT}`
          this.videoPoster = videoItem.thumb_url
          const vid = videoItem.access_url
          this.videoURL = ''
          if (vid.indexOf('public/')) {
            this.videoURL = vid.slice(vid.indexOf('public/') + 7)
          } else if (vid.indexOf('Public')) {
            this.videoURL = vid.slice(vid.indexOf('Public/') + 7)
          } else if (vid.indexOf('PUBLIC')) {
            this.videoURL = vid.slice(vid.indexOf('PUBLIC/') + 7)
          }
          Storage.get(this.videoURL, { download: false }).then(result1 => {
            console.log('play Video File: GOT RESULT')
            console.log(result1)
            this.videoSrc = result1
            // Open player modal
            this.showVideoModal = true
            console.log(`showVideoModal: ${this.showVideoModal}`)
            // eslint-disable-next-line no-undef
            console.log(this.$refs.vmodal)
            this.$refs.vmodal.show()
          })
        }
      }
    },
    downloadVideo() {
      console.log('downloadVideo')
      if (ability.can('read', 'cameras') || ability.can('read', 'camerasmanagement')) {
        const result = Storage.get(this.videoURL, { download: true }).then(result1 => {
          console.log(result1)
          console.log(result)
          const fileUrl = window.URL.createObjectURL(result1.Body)
          const fileLink = document.createElement('a')
          fileLink.href = fileUrl
          fileLink.setAttribute('download', `${this.videoTitle}.mp4`)
          document.body.appendChild(fileLink)
          fileLink.click()
        })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import "@core/scss/base/pages/app-ecommerce.scss";
</style>
