import { ref } from '@vue/composition-api'
import moment from 'moment'

export const useVideosFiltersSortingAndPagination = () => {
  const filters = ref({
    q: '',
    startTime: '',
    endTime: '',
    page: 1,
    perPage: 6,
  })

  return {
    filters,
  }
}

export const useCameraMediaData = () => {
  const products = ref([])
  const fetchVideoItemsFromMedia = qvars => {
    console.log(`fetchVideoItemsFromMedia: ${qvars.currentImageDateTime}`)
    console.log(qvars)
    return new Promise(resolve => {
      // Loop through the camera media and pickout any video items
      const cameraMediaItems = qvars.cameraMedia.Items
      const tmpVideoItemsArr = []
      cameraMediaItems.forEach(item => {
        if (typeof item.video_file !== 'undefined' && item.video_file !== null && item.video_file !== '') {
          // Only add video items for the current day
          console.log('Found video item')
          const itemDateTime = new Date(item.time_camera)
          const currDateTime = new Date(qvars.currentImageDateTime)
          let startDateTime = ''
          let endDateTime = ''
          const itemDateTimeGetTime = itemDateTime.getTime()
          let startDateTimeGetTime = 0
          let endDateTimeGetTime = 0
          if (moment(currDateTime).format('YYYYMMDD') === moment(itemDateTime).format('YYYYMMDD')) {
            // Filter by StartTime and endTime
            if (qvars.startTime !== null && qvars.startTime !== '' && qvars.endTime !== null && qvars.endTime !== '') {
              // both start and end time have been specified
              startDateTime = new Date(`${moment(itemDateTime).format('YYYY-MM-DD')} ${qvars.startTime}`)
              endDateTime = new Date(`${moment(itemDateTime).format('YYYY-MM-DD')} ${qvars.endTime}`)
              startDateTimeGetTime = startDateTime.getTime()
              endDateTimeGetTime = endDateTime.getTime()
              if (itemDateTimeGetTime >= startDateTimeGetTime && itemDateTimeGetTime <= endDateTimeGetTime) {
                tmpVideoItemsArr.push(item)
              }
            } else if (qvars.startTime !== null && qvars.startTime !== '') {
              // Only Start Time specified
              startDateTime = new Date(`${moment(itemDateTime).format('YYYY-MM-DD')} ${qvars.startTime}`)
              startDateTimeGetTime = startDateTime.getTime()
              if (itemDateTimeGetTime >= startDateTimeGetTime) {
                tmpVideoItemsArr.push(item)
              }
            } else if (qvars.endTime !== null && qvars.endTime !== '') {
              // Only End Time specified
              endDateTime = new Date(`${moment(itemDateTime).format('YYYY-MM-DD')} ${qvars.endTime}`)
              endDateTimeGetTime = endDateTime.getTime()
              if (itemDateTimeGetTime <= endDateTimeGetTime) {
                tmpVideoItemsArr.push(item)
              }
            } else {
              // No Start or end time specified
              tmpVideoItemsArr.push(item)
            }
          }
        }
      })
      // set overlay to false
      tmpVideoItemsArr.forEach((item2, index) => {
        tmpVideoItemsArr[index].showLoadingOverlay = false
        tmpVideoItemsArr[index].isDownloading = false
      })
      // Paginate
      const start = (qvars.page - 1) * qvars.perPage
      const end = start + qvars.perPage
      const finalArr = tmpVideoItemsArr.slice(start, end)
      // Return
      const myReturn = {}
      myReturn.videoItems = finalArr
      myReturn.total = tmpVideoItemsArr.length
      resolve(myReturn)
    })
  }

  return {
    products,
    fetchVideoItemsFromMedia,
  }
}
