<template>
  <div>
    <b-sidebar
      v-if="$can('read', 'cameras') || $can('read', 'camerasmanagement')"
      id="sidebar-add-new-event"
      sidebar-class="sidebar-lg"
      :visible="isEventHandlerSidebarActive"
      bg-variant="white"
      shadow
      backdrop
      header-class="d-block border-bottom-primary"
      body-class="mt-1 mb-1"
      right
      @change="(val) => $emit('update:is-event-handler-sidebar-active', val)"
    >
      <template #header="{ hide }">
        <!-- Header -->
        <b-row class="mt-1">
          <b-col>
            <h5 class="mb-0">
              <h4>{{ sidebarTitle }}</h4>
            </h5>
          </b-col>
          <b-col class="text-right">
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </b-col>
        </b-row>
      </template>
      <template
        v-if="finalCameraMediaArr.length > 0"
        #default=""
      >
        <!-- Images and Videos List -->
        <div
          v-for="theDateItem in finalCameraMediaArr"
          :key="'theDate-' + theDateItem.theDate.replace('T','').replace(' ','').replace('-','').replace(':','')"
          class="ml-1 mr-1"
        >
          <!-- Date Sub-Header -->
          <b-row class="mt-2 mb-1 border-bottom-primary">
            <b-col
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <h5 class="mb-0">
                <h4>{{ theDateItem.theDateDisplay }}</h4>
              </h5>
            </b-col>
          </b-row>

          <!-- Cameras List -->
          <section
            class="grid-view"
          >
            <b-card
              v-for="mediaItem in theDateItem.mediaItems"
              :id="mediaItem.time_camera.replace('T','').replace(' ','').replaceAll('-','').replaceAll(':','')"
              :ref="mediaItem.time_camera.replace('T','').replace(' ','').replaceAll('-','').replaceAll(':','')"
              :key="'filmstrip-' + mediaItem.time_camera.replace('T','').replace(' ','').replaceAll('-','').replaceAll(':','')"
              no-body
            >
              <div
                class="item-img text-center"
                @click="$emit('itemclicked', mediaItem)"
              >
                <!-- <b-img-lazy
                  :alt="`${mediaItem.clientname}-${mediaItem.camera_id}-${mediaItem.sitename}`"
                  fluid
                  class="card-img-top"
                  :src="mediaItem.thumb_url"
                  throttle="10000"
                /> -->
                <thumbs-lazy-image-component
                  class="card-img-top"
                  :src="mediaItem.access_url"
                  src-placeholder="@/assets/images/noImage.png"
                />
              </div>

              <b-card-footer class="pt-1 pb-1 border-dark">
                <b-row>
                  <b-col cols="8">
                    <small class="text-muted">Image Time: </small><small>{{ formatDateTimeAMPM(mediaItem.time_camera.replace('T', ' ')) }}</small>
                  </b-col>
                  <b-col
                    v-if="mediaItem.video_file !== undefined"
                    cols="4"
                  >
                    <b-badge
                      pill
                      variant="light-success"
                      class="text-capitalize"
                    >
                      Video
                    </b-badge>
                  </b-col>
                  <b-col
                    v-else
                    cols="4"
                  >
                    <b-badge
                      pill
                      variant="light-success"
                      class="text-capitalize"
                    >
                      Image
                    </b-badge>
                  </b-col>
                </b-row>
              </b-card-footer>
            </b-card>
          </section>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BRow, BCol, BCard, BCardFooter, BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'
import { watch } from '@vue/composition-api'
// import useCalendarEventHandler from './useCalendarEventHandler'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ThumbsLazyImageComponent from '../thumbsLazyImageComponent'
import ability from '../../../../../libs/acl/ability'

export default {
  components: {
    BSidebar, BRow, BCol, BCard, BCardFooter, BBadge, ThumbsLazyImageComponent,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isEventHandlerSidebarActive',
    event: 'update:is-event-handler-sidebar-active',
  },
  props: {
    isEventHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    event: {
      type: Object,
      required: true,
    },
    clearEventData: {
      type: Function,
      required: true,
    },
    cameraData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      finalCameraMediaArr: [],
      showLoadingOverlay: false,
      cameraMedia: {},
    }
  },
  watch: {
    event() {
      const now = new Date()
      const startDate = new Date(this.event.start)
      if (this.event.start !== '' && startDate.getTime() <= now.getTime() && this.isEventHandlerSidebarActive) {
        this.getCameraMedia()
      } else {
        // this.isEventHandlerSidebarActive = false
        this.$emit('update:is-event-handler-sidebar-active', false)
      }
    },
  },
  setup(props) {
    let sidebarTitle = ''
    // Set Sidebar Title
    if (typeof props.event.title !== 'undefined' && props.event.title.includes('Videos')) {
      // show only video images
      sidebarTitle = 'Videos'
    } else if (typeof props.event.title === 'undefined' || !props.event.title.includes('Videos')) {
      sidebarTitle = 'Images & Videos'
    }

    const formatDateTimeAMPM = date => {
      const theDate = new Date(date.replace(/-/g, '/'))
      const year = theDate.getFullYear()
      let month = theDate.getMonth() + 1
      if (month < 10) month = `0${month}`
      let day = theDate.getDate()
      if (day < 10) day = `0${day}`
      let hours = theDate.getHours()
      let minutes = theDate.getMinutes()
      const ampm = hours >= 12 ? 'PM' : 'AM'
      hours %= 12
      hours = hours || 12
      minutes = minutes.toString().padStart(2, '0')
      const strTime = `${year}-${month}-${day} ${hours}:${minutes} ${ampm}`
      return strTime
    }

    return {
      sidebarTitle,
      formatDateTimeAMPM,
    }
  },
  created() {
    watch(this.event, () => {
      // console.log('watch: cameraMedia')
      this.getCameraMedia()
    }, {
      deep: true,
    })

    // this.processCameraMedia()

    window.addEventListener('keyup', this.doKeyboardCommands)
  },
  destroyed() {
    window.removeEventListener('keyup', this.doKeyboardCommands)
  },
  methods: {
    doKeyboardCommands(e) {
      const cmd = String.fromCharCode(e.keyCode).toLowerCase()
      // Next Previous
      if (cmd === '&') {
        // scroll up
      } else if (cmd === '(') {
        // Scroll down
      }
    },
    getCameraMedia() {
      console.log('getCameraMedia')
      console.log(this.event)

      if ((ability.can('read', 'cameras') || ability.can('read', 'camerasmanagement')) && this.event.start !== '') {
        this.cameraMedia = {}
        const todayFormated = moment(this.event.start).format('YYYYMMDD')
        const qvars1 = {}
        qvars1.camera_id = this.cameraData.camera_id
        qvars1.media_type = 'photos'
        qvars1.clientname = this.cameraData.clientname
        qvars1.get_date = todayFormated
        qvars1.time_zone = this.cameraData.time_zone
        // Removed to force full day data
        // if (startTime !== '' && startTime !== null) {
        //   qvars1.start_time = startTime
        // }
        // if (endTime !== '' && endTime !== null) {
        //   qvars1.end_time = endTime
        // }
        // if (startKey !== '' && startKey !== null) {
        //   const theStartKey = {}
        //   theStartKey.PK = startKey.PK
        //   theStartKey.SK = startKey.SK
        //   qvars1.start_key = theStartKey
        // }
        console.log(qvars1)
        // Get the list of images for the camera
        this.showLoadingOverlay = true
        store
          .dispatch('app-cameraconsole/fetchCameraMedia', qvars1)
          .then(response1 => {
            console.log('app-cameraconsole/fetchCameraMedia')
            console.log(response1)
            // Check to see if the getDate matches the current date and add new items or replace data
            this.cameraMedia.Count = response1.Count
            this.cameraMedia.Items = response1.Items
            this.cameraMedia.LastEvaluatedKey = response1.LastEvaluatedKey
            this.cameraMedia.ScannedCount = response1.ScannedCount
            this.showLoadingOverlay = true
            console.log(this.cameraMedia)
            this.processCameraMedia()
          })
          .catch(error => {
            console.log(error)
            this.showLoadingOverlay = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'An error occured getting media data.',
                icon: 'CameraIcon',
                variant: 'danger',
              },
            })
          })
      }
    },
    processCameraMedia() {
      console.log('FILMSTRIP: processCameraMedia')
      const that = this
      // Go through the camera media and organize into days
      this.finalCameraMediaArr = []
      // first loop through and get the list of unique dates
      this.cameraMedia.Items.forEach(item => {
        const theDate = new Date(item.time_camera)
        const getDate = moment(theDate).format('YYYYMMDD')
        const obj = this.finalCameraMediaArr.find(o => o.theDate === getDate)
        if (typeof obj === 'undefined') {
          const theDateDisplay1 = moment(theDate).format('MMMM Do, YYYY')
          const tmp = { theDate: getDate, theDateDisplay: theDateDisplay1 }
          this.finalCameraMediaArr.push(tmp)
        }
      })
      console.log('finalCameraMediaArr')
      console.log(this.finalCameraMediaArr)
      // Then loop through and add in the media items
      this.finalCameraMediaArr.forEach((dateItems, dateIndex) => {
        const tmpArr = []
        this.cameraMedia.Items.forEach(item => {
          const theDate = new Date(item.time_camera)
          const getDate = moment(theDate).format('YYYYMMDD')
          if (dateItems.theDate === getDate) {
            // filter for images or video if specified
            if (typeof that.event.title !== 'undefined' && that.event.title.includes('Videos') && typeof item.video_file !== 'undefined') {
              // show only video images
              tmpArr.push(item)
              this.sidebarTitle = 'Videos'
            } else if (typeof that.event.title === 'undefined' || !that.event.title.includes('Videos')) {
              tmpArr.push(item)
              this.sidebarTitle = 'Images & Videos'
            }
          }
        })
        this.finalCameraMediaArr[dateIndex].mediaItems = tmpArr
      })
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
